import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import jquery from 'jquery'

import FluidImage from 'components/FluidImage'

import css from './ImageInput.scss'
import placeholder from 'assets/placeholder.svg'

class ImageInput extends React.Component {
    static propTypes = {
        userImage: PropTypes.bool.isRequired,
        deleteImageUrl: PropTypes.string.isRequired,
        image: PropTypes.string,
        labels: PropTypes.shape({
            profileImage: PropTypes.string.isRequired,
            deleteProfileImage: PropTypes.string.isRequired,
            uploadNewProfileImage: PropTypes.string.isRequired,
            profileImageInformation: PropTypes.string
        }).isRequired
    }

    static defaultProps = {
        roundedImage: false
    }

    state = {
        file: '',
        image: null,
        shouldDeleteFromDatabase: this.props.image ? true : false,
        shouldShowDeleteLink: this.props.image ? true : false
    }

    _handleImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                image: reader.result,
                shouldShowDeleteLink: true
            });
        }
        reader.readAsDataURL(file)
    }

    _getImageUrl = () => {
        const imageUrl = this.state.image ? this.state.image : this.props.image

        if(!imageUrl) {
            return placeholder
        }

        return imageUrl
    }


    _deleteImage = () =>
    {
        if(this.state.shouldDeleteFromDatabase){
            jquery.ajax({
                url: this.props.deleteImageUrl
            }).done(function() {
            });
            this.setState({shouldDeleteFromDatabase: false})
        }
        this.setState({
            file: null,
            image: placeholder,
            shouldShowDeleteLink: false
        });
        document.getElementById(this.props.labels.profileImage).value="";
    }

    render() {
        const {
            userImage,
            labels: {
                profileImage,
                uploadNewProfileImage,
                deleteProfileImage,
                profileImageInformation
            }
        } = this.props

        return (
            <div className={css.container}>
                <FluidImage
                    className={cn(css.image, {
                        [css.rounded]: userImage,
                    })}
                    url={this._getImageUrl()}
                />
                <div className={cn(css.information)}>
                    <label className={cn("h4", css.title)}>{profileImage}</label>
                    {profileImageInformation && (
                        <p className={cn("typo-small", css.text)}>{profileImageInformation}</p>
                    )}
                    <div className={css.actions}>
                        <label className={cn(css.label, css.add)} htmlFor={this.props.labels.profileImage} >{uploadNewProfileImage}</label>
                        {this.state.shouldShowDeleteLink &&
                            <label className={cn(css.label, css.delete)} onClick={this._deleteImage}>{deleteProfileImage}</label>
                        }
                    </div>
                </div>
                <input
                    className={css.input}
                    id={this.props.labels.profileImage}
                    name="file"
                    onChange={this._handleImageChange}
                    type="file"
                />
            </div>
        );
    }
}

export default ImageInput
