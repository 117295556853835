

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import css from './EmbeddedFrame.scss'
import {getScrollingPropertyValue} from "./utils"
import debounce from 'lodash/debounce'
import breakpoints from 'js/breakpoints';
 
const EmbeddedFrame = ({ src, id, autoResize, heading, textHtml, width, desktopHeight, mobileHeight, scrollable, title }) => {
    const [frameHeight, setFrameHeight] = useState(mobileHeight)

    const setResponsiveFrameHeight = () => {
        setFrameHeight(breakpoints.m() ? desktopHeight : mobileHeight)
    }

    useEffect(() => {
        setResponsiveFrameHeight()
        const debouncedSetHeight = debounce(setResponsiveFrameHeight, 300)

        if (autoResize) {
            const script = document.createElement("script");
            script.src = "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js";
            script.onload = function() {
                iFrameResize();
            };
            document.body.appendChild(script);
        } 

        window.addEventListener('resize', debouncedSetHeight)
        return () => window.removeEventListener('resize', debouncedSetHeight)
    }, [])

    return <div className={css.embeddedFrameBlock}>
        {heading && <h2 className={css.embeddedFrameHeading}>{heading}</h2>}
        {textHtml && <div
            className={css.embeddedFrameText}
            dangerouslySetInnerHTML={{
                __html: textHtml
            }}
        />}
        <iframe 
            id={id}
            frameBorder="0"
            className={css.embeddedFrame} 
            src={src} 
            height={frameHeight}
            style={{width}}
            scrolling={getScrollingPropertyValue(scrollable)} 
            title={title}
        />
    </div>    
};

EmbeddedFrame.defaultProps = {
    desktopHeight: '500',
    mobileHeight: '500',
    width: '100%'
}

EmbeddedFrame.propTypes = {
    id: PropTypes.string,
    src: PropTypes.string.isRequired,
    heading: PropTypes.string,
    textHtml: PropTypes.string,
    width: PropTypes.string,
    desktopHeight: PropTypes.string,
    mobileHeight: PropTypes.string,
    scrollable: PropTypes.bool,
    autoResize: PropTypes.bool,
    title: PropTypes.string
};

export default EmbeddedFrame;