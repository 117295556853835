import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './ReadMore.scss'

const checkIfTextIsClamped = (el) => {
    if (el && el.current) {
        return el.current.scrollHeight > el.current.clientHeight + 2;
    }
    return false
}

const truncateStyles = (numberOfLines) => ({
    WebkitLineClamp: numberOfLines
});

const ReadMore = ({ className, readMoreText, readLessText, destroyOnMobile = false, numberOfLines = 2, children }) => {
    const el = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTextClamped, setIsTextClamped] = useState(false);

    useEffect(() => {
        if (el.current) {
            setIsTextClamped(checkIfTextIsClamped(el));
        }
    }, [el.current]);

    const toggleExpandedState = () => setIsExpanded(prev => !prev);

    return (
        <div className={cn('read-more', {[className]: className})}>
            <div 
                ref={el} 
                className={cn('read-more__content', {
                    'read-more__content_destroy-on-mobile': destroyOnMobile,
                    'read-more__content_truncated': !isExpanded,
                })}
                style={isExpanded ? {} : truncateStyles(numberOfLines)}
            >
                {children}
            </div>
            {isTextClamped && <button 
                className={cn('read-more__expand-btn', {
                    'hidden-tablet': destroyOnMobile
                })} 
                onClick={toggleExpandedState}
            >
                {isExpanded ? readLessText : readMoreText}
            </button>}
        </div>
    );
};

ReadMore.propTypes = {
    className: PropTypes.string,
    readMoreText: PropTypes.string.isRequired,
    readLessText: PropTypes.string.isRequired,
    destroyOnMobile: PropTypes.bool,
    children: PropTypes.node
};

ReadMore.propTypesMeta = 'exclude';

export default ReadMore;