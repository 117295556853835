import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import css from './InsightTextField.scss'

class InsightTextField extends React.Component {
    static propTypes = {
        backgroundColor: PropTypes.oneOf(['deep-blue', 'sky-blue', 'sea-blue', 'sea-green']),
        optionsText: PropTypes.string,
        text: PropTypes.string,
        link: PropTypes.shape({
            url: PropTypes.string,
            text: PropTypes.string
        }),
        isUnderEdit: PropTypes.bool
    }

    static defaultProps = {
        backgroundColor: "deep-blue"
    }

    render() {
        const {
            backgroundColor,
            text,
            link,
            optionsText,
            isUnderEdit
        } = this.props

        return (
            <div className={cn(css.insightTextField, {
                ["bg-color-" + backgroundColor]: backgroundColor
            })}>
                {isUnderEdit && (
                    <div data-epi-property-name="Options" data-epi-use-mvc="True" className={css.insightTextField__options}>{optionsText}</div>
                )}
                {(isUnderEdit || text) && (
                    <div className={css.insightTextField__text} data-epi-property-name="Text">{text}</div>
                )}
                {(isUnderEdit || (link.url && link.text)) && (
                    <div data-epi-property-name="Link" data-epi-use-mvc="True">
                        <a href={link.url} className="shortcut negative">{link.text}</a>
                    </div>
                )}
            </div>
        )
    }
}

export default InsightTextField