import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import BackgroundMedia from 'components/BackgroundMedia'
import imageResizer from 'components/ResponsiveImage/ImageResizer.js'
import './HeroBlock.scss'

const HeroBlock = ({ heading, text, mediaClassName, background, isMain }) => {
    const HeadingTag = isMain ? 'h1' : 'h2';
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    useEffect(() => {
        setImageWidth(imageResizer.calcImageWidth(window.screen.width, 100));
        setImageHeight(imageResizer.calcImageHeight(window.screen.height, 100));
    });

    return (
        <div className="hero-block">
            {background && (background.video || background.image) && (
                <BackgroundMedia
                    className={cn({
                        [mediaClassName]: mediaClassName,
                    })}
                    {...background}
                    photographerClassName="hero-block__photographer"
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                />
            )}

            <div className="hero-block__text-container">
                {heading && (
                    <HeadingTag className="hero-block__heading">
                        <span className="hero-block__heading-wrapper">
                            {heading}
                        </span>
                    </HeadingTag>
                )}
                {text && (
                    <div className="hero-block__text">
                        <span className="hero-block__text-wrapper">{text}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

HeroBlock.propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    background: PropTypes.exact(BackgroundMedia.propTypes),
    mediaClassName: PropTypes.string,
    isMain: PropTypes.bool,
}

export default HeroBlock
