import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import css from './BackgroundVideo.scss'

const BackgroundVideo = ({
    url,
    previewImage,
    pause,
}) => {
    const videoRef = useRef(null)
    
    useEffect(() => {
        if (videoRef && videoRef.current) {
            if (pause) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
        }
    }, [pause, videoRef])

    return (
        <div className={css.backgroundVideoWrapper}>
            <video 
                ref={videoRef} 
                src={url} 
                poster={previewImage} 
                className={css.backgroundVideo} 
                loop 
                autoPlay 
                muted
                playsInline
            ></video>
        </div>
    )
}

BackgroundVideo.propTypes = {
    url: PropTypes.string.isRequired,
    previewImage: PropTypes.string,
    pause: PropTypes.bool,
}

export default BackgroundVideo