import React from 'react'
import PropTypes from 'prop-types'
import SelectOption from './SelectOption'

const TagFilter = ({ options, selectAllText, selectedAll, onFilterChange }) => {
    const [optionsList, setOptionsList] = React.useState(null)

    const unselectAll = list => {
        list.forEach(item => {
            if (item.isSelected) item.isSelected = false
        })
    }

    const selectAllOptions = () => {
        unselectAll(optionsList)
        onFilterChange(null)
    }

    const handleCange = (e, index) => {
        const currentTag = e.currentTarget.value
        const currentCheckboxesList = optionsList
        const clickedItem = currentCheckboxesList[index]
        unselectAll(currentCheckboxesList)
        clickedItem.isSelected = true

        setOptionsList(currentCheckboxesList)
        onFilterChange(currentTag)
    }

    React.useEffect(() => {
        if (selectedAll) {
            const newOptions = options && options.map(option => ({ ...option }))
            unselectAll(newOptions)
            setOptionsList(newOptions)
        } else {
            setOptionsList(options)
        }
    }, [])

    return (
        <div className="latest-articles-block__tag-filter">
            <SelectOption
                label={selectAllText}
                isSelected={selectedAll}
                value="all"
                onChange={selectAllOptions}
            />
            {optionsList &&
                optionsList.map((option, index) => (
                    <SelectOption
                        key={index}
                        {...option}
                        onChange={e => handleCange(e, index)}
                    />
                ))}
        </div>
    )
}

TagFilter.propTypes = {
    selectedAll: PropTypes.bool,
    selectAllText: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.exact(SelectOption.propTypes)),
    onFilterChange: PropTypes.func
}
export default TagFilter
