import React from 'react'
import PropTypes from 'prop-types'
import jquery from 'jquery'
import cn from 'classnames'

import css from './ScrollHint.scss'

class ScrollHint extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        className: PropTypes.string,
        scrollToPosition: PropTypes.number,
        hideOnScroll: PropTypes.bool
    }

    static defaultProps = {
        hideOnScroll: true
    }

    componentDidMount() {
        if(typeof window !== 'undefined') {
            window.addEventListener('scroll', this._handleScroll)
            if(window.pageYOffset > 0) {
                this._handleScroll()
            }
        }
    }

    _fadeOut = () => {
        if(this.hint) {
            const element = jquery(this.hint)
            element.animate({ opacity: 0 }, 500, () => element.hide())
        }
    }

    _handleScroll = () => {
        window.removeEventListener('scroll', this._handleScroll)
        this.props.hideOnScroll && this._fadeOut()
    }

    _handleClick = e => {
        e.preventDefault()

        const {
            scrollToPosition
        } = this.props;
        var body = jquery('html, body')
        body.stop().animate({ scrollTop: scrollToPosition ? scrollToPosition : window.innerHeight }, '500', 'swing')
    }

    render() {
        const {
            label,
            className
        } = this.props

        return (
            <button
                className={cn(css.hint, className)}
                onClick={this._handleClick}
                ref={node => this.hint = node}
                title={label}
            >
                <svg focusable="false">
                    <use xlinkHref="#icon-arrow-up"></use>
                </svg>
            </button>
        )
    }
}

export default ScrollHint
