import React from 'react'
import PropTypes from 'prop-types'
import TypedLink from 'components/Models/TypedLink'
import LinkWithIcon from 'components/LinkWithIcon'
import PublishedDate from 'components/PublishedDate'
import './CountryPageLatestReportsAndPublicationsBlock.scss'
import RichText from 'components/RichText'

const CountryPageLatestReportsAndPublicationsBlockItem = ({ 
    link, 
    publishedLabel, 
    publishedDate,
    publishedDateFormatted,
    introHtml
}) => {
    return (
        <React.Fragment>
            {link &&<LinkWithIcon 
                {...link} 
                className="country-page-latest-reports-and-publications-block__item-link"
            />}
            <PublishedDate
                label={publishedLabel}
                date={publishedDate}
                dateFormatted={publishedDateFormatted}
                className="country-page-latest-reports-and-publications-block__item-published-date"
             />
            {introHtml && 
                <RichText 
                    text={introHtml} 
                    className="
                        country-page-latest-reports-and-publications-block__item-text 
                        rich-text_in-country-page-latest-reports-and-publications-block
                    " 
                />
            }
        </React.Fragment>
    )
}

CountryPageLatestReportsAndPublicationsBlockItem.propTypes = {
    link: PropTypes.exact(TypedLink.propTypes),
    introHtml: PropTypes.string,
    publishedDate: PropTypes.string,
    publishedDateFormatted: PropTypes.string,
    publishedLabel: PropTypes.string,
}

CountryPageLatestReportsAndPublicationsBlockItem.propTypesMeta = {
    publishedLabel: 'exclude'
};

export default CountryPageLatestReportsAndPublicationsBlockItem
