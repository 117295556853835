import React from 'react'
import PropTypes from 'prop-types'
import RichText from 'components/RichText';
import './CountryPageRichTextBlock.scss'

const CountryPageRichTextBlock = ({ heading, textHtml }) => {
    return (
        <div className="country-page-rich-text-block">
            {heading && <h2 className="country-page-rich-text-block__heading">{heading}</h2>}
            <RichText text={textHtml} className="country-page-rich-text-block__text rich-text_in-country-page" />
        </div>
    )
}

CountryPageRichTextBlock.propTypes = {
    heading: PropTypes.string,
    textHtml: PropTypes.string,
}

export default CountryPageRichTextBlock
