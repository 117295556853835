/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import cn from 'classnames'
import './LinkButton.scss'

const LinkButton = ({
    className,
    openInNewTab = false,
    url,
    text,
    onClick
}) => {

    const RootComponent = url ? 'a' : 'button'

    const props = {
        ...(url && {
            href: url,
            target: openInNewTab ? '_blank' : null,
            rel: openInNewTab ? 'noopener noreferrer' : null
        }),
        ...(onClick && typeof onClick === 'function' && { onClick: onClick })
    }

    return (
        <RootComponent
            className={cn('link-button', className)}
            {...props}
        >
            {text && <span className={cn('link-button__text')}>{text}</span>}
        </RootComponent>
    )
}

LinkButton.propTypes = Object.assign({}, Link.propTypes, {
    onClick: PropTypes.func
})

LinkButton.meta = 'exclude'

export default LinkButton
