import jquery from 'jquery'

export function replaceAll(string, search, replacement) {
    return string.split(search).join(replacement)
}

if(! String.prototype.replaceAll) {
    String.prototype.replaceAll = function(search, replacement) {
        console.warn('Avoid using String.prototype.replaceAll')
        return replaceAll(this, search, replacement);
    }
}

export function format(string, replacements) {
    if(Array.isArray(replacements)) {
        replacements.forEach(function(replacement, index) {
            string = replaceAll(string, `{${index}}`, replacement)
        })
        return string
    } else {
        return replaceAll(string, '{0}', replacements)
    }
}

if(! String.prototype.format) {
    String.prototype.format = function(replacements) {
        console.warn('Avoid using String.prototype.format')
        return format(this, replacements)
    }
}

if(! String.prototype.stripHtmlTags) {
    String.prototype.stripHtmlTags = function() {
        return this.replace(/(<([^>]+)>)/ig,"")
    }
}

if(! String.prototype.downFit) {
    String.prototype.downFit = function(width, height) {
        if(this.indexOf('data:image/') >= 0) return this
        if(! height) height = width
        return `${this}?transform=DownFit&width=${width}&height=${height}`
    }
}

if(! String.prototype.downResizeMin) {
    String.prototype.downResizeMin = function(width, height) {
        if(this.indexOf('data:image/') >= 0) return this
        if(! height) height = width
        return `${this}?transform=DownFit&width=${width}&height=${height}`
    }
}

export function toggleScrollLock() {
    if(document.body.style.overflow == 'hidden') {
        document.body.removeAttribute('style')
    } else {
        const initialWidth = document.body.getBoundingClientRect().width
        document.body.style.overflow = 'hidden';
        const newWidth = document.body.getBoundingClientRect().width
        document.body.style.marginRight = (newWidth - initialWidth) + "px"
    }
}

const $focusableElems = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

export function trapTabKey(obj, e) {
    // if TAB or SHIFT-TAB pressed, keep focus on focuable elements within the modal
    if (e.which === 9) {
        var o = jquery(obj).find('*'),
            focusableItems,
            focusedItem,
            numberOfFocusableItems,
            focusedItemIndex;

        focusableItems = o.filter($focusableElems).filter(':visible');
        focusedItem = jquery(':focus');
        numberOfFocusableItems = focusableItems.length;
        focusedItemIndex = focusableItems.index(focusedItem);

        if (e.shiftKey) {
            // Back tab - if focused on first item and user preses back-tab, go to the last focusable item
            if (focusedItemIndex === -1 || focusedItemIndex === 0) {
                focusableItems.get(numberOfFocusableItems - 1).focus();
                e.preventDefault();
            }
        }
        else {
            // Forward tab - if focused on the last item and user preses tab, go to the first focusable item
            if (focusedItemIndex === -1 || focusedItemIndex === numberOfFocusableItems - 1) {
                focusableItems.get(0).focus();
                e.preventDefault();
            }
        }
    }
}

export function scrollToBlock(blockId, useTopMenuOffset = true) {
    const targetBlock = document.getElementById(blockId);

    if (targetBlock) {
        const topMenu = document.getElementById('top-menu');
        const offset = useTopMenuOffset && topMenu ? topMenu.offsetHeight : 0;
        const y = targetBlock.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }
}
