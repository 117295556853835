import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './FluidImage.scss'

const getImageUrl = (url, size) => size ? url.downResizeMin(size) : url

const FluidImage = ({
    className,
    url,
    alt,
    focusPoint,
    fallback,
    size
}) => {
    const imageUrl = url ? getImageUrl(url, size) : fallback

    return (
        <div
            className={cn('fluid-image', className)}
            style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: focusPoint ? `${focusPoint.x}% ${focusPoint.y}%` : null
            }}
        >
            {url && <img src={imageUrl} alt={alt ? alt : ''} />}
        </div>
    )
}

FluidImage.propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    alt: PropTypes.string,
    focusPoint: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
    }),
    fallback: PropTypes.string,
    size: PropTypes.number
}

export default FluidImage
