import React from 'react'
import { render } from 'react-dom'

import Video from 'containers/Video'

document.addEventListener('DOMContentLoaded', () => {
    const buttons = Array.from(document.querySelectorAll('.video-button'));

    if(buttons.length) {
        const body = document.body
        const div = document.createElement('div')
        body.appendChild(div)

        render(
            <Video
                buttons={buttons}
            />,
            div
        )
    }
});
