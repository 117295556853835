import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Portal } from 'react-portal';

import Icon from 'components/Icon';

import { toggleScrollLock, trapTabKey } from '../../utils';

import css from './Modal.scss';

export const modalType = {
  mini: "mini",
  video: "video",
  employee: "employee"
}

const Modal = ({ children, labels, hasImage, type, onClose }) => {
    const [closing, setClosing] = React.useState(false);
    const timeout = 300;

    const _close = (e) => {
        if (this && closing) return;
        setClosing(true);

        window.setTimeout(() => {
            onClose(e);
        }, timeout);
    };

    const _onKeydown = (e) => {
        if (e.which === 27) {
      // Close the modal and set focus on the element which opened the modal..
      // TODO: "Cache" previously focused element
            _close(e);
            window.removeEventListener('keydown', _onKeydown)
        } else {
            trapTabKey(document.getElementById('modal'), e);
        }
    };

    const _onClick = (e) => {
    // Only close if you clicked the modal background
        if (e.target == e.currentTarget) _close(e);
    };

    React.useEffect(() => {
      window.addEventListener('keydown', _onKeydown);
    }, [])

    React.useEffect(() => {
      window.setTimeout(toggleScrollLock, closing ? timeout : 0);
    }, [closing])

    return (
    <Portal isOpened={true}>
      <div
        className={cn(css.modal, {
            [css[type]]: type,
            [css.hasImage]: hasImage,
            [css.closing]: closing,
        })}
        id="modal"
        onClick={_onClick}
        tabIndex="0"
      >
        <div className={css.content}>{children}</div>
        <button
          aria-controls="modal"
          className={cn('flex', css.help)}
          data-modal="close"
          onClick={_close}
        >
          <div>
            <span>{labels.close}</span>
            <Icon use="close" />
          </div>
        </button>
      </div>
    </Portal>
    );
};

Modal.defaultProps = {
    labels: {
        close: 'Close (ESC)',
    },
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(Object.values(modalType)),
    hasImage: PropTypes.bool,
    labels: PropTypes.shape({
        close: PropTypes.string.isRequired,
    }),
    onClose: PropTypes.func,
};

export default Modal;
