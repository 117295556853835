import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import PoorText from 'components/PoorText'
import LinkWithIcon from 'components/LinkWithIcon'
import MarketInsightLatestListItem from 'components/MarketInsightLatestListItem'

import './MarketInsightLatestBlogPostsBlock.scss'

const MarketInsightLatestBlogPostsBlock = ({
    heading,
    introHtml,
    items,
    earlierPublicationsLink,
    lang
}) => (
    <div className='market-insight-latest-blog-posts-block'>
        <div className='market-insight-latest-blog-posts-block__container'>
            {heading && <h3 className='market-insight-latest-blog-posts-block__heading'>{heading}</h3>}
            {introHtml && <PoorText text={introHtml} className='poor-text_in-market-insight-latest-blog-posts-block' />}
            {items && items.length > 0 && <div className='market-insight-latest-blog-posts-block__list'>
                {items.map((item, index) => (
                    <div className='market-insight-latest-blog-posts-block__item' key={index}>
                        <MarketInsightLatestListItem {...item} publishedLabel={lang.publishedLabel} />
                    </div>
                ))}
            </div>}
            {earlierPublicationsLink && 
                <div className='market-insight-latest-blog-posts-block__link-holder'>
                    <LinkWithIcon
                        {...earlierPublicationsLink}
                        className='market-insight-latest-blog-posts-block__link'
                    />
                </div>
            }
        </div>
    </div>
)

MarketInsightLatestBlogPostsBlock.propTypesMeta = {
    publishedLabel: 'exclude',
}

MarketInsightLatestBlogPostsBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact(MarketInsightLatestListItem.propTypes)),
    earlierPublicationsLink: PropTypes.exact(Link.propTypes),
    lang: PropTypes.shape({
        publishedLabel: PropTypes.string.isRequired,
    }).isRequired
}

export default MarketInsightLatestBlogPostsBlock