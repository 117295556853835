import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FilterTableItem from 'components/FilterTableItem'
import StatisticLink from 'components/StatisticLink'

import css from './WeekMonthStatistic.module.scss'

function openReportsFilter(report) {
    return report.hasAccess
}

function closedReportsFilter(report) {
    return !report.hasAccess && !report.isLoggedIn
}

function noAccessReportsFilter(report) {
    return !report.hasAccess && report.isLoggedIn
}

export default class WeekMonthStatistic extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        hide: PropTypes.bool.isRequired,
        reports: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            size: PropTypes.string.isRequired,
            filetype: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            hasAccess: PropTypes.bool.isRequired,
            isLoggedIn: PropTypes.bool.isRequired,
        })).isRequired,
        labels: PropTypes.shape({
            closedReports: PropTypes.string.isRequired,
            closedReportsDescription: PropTypes.string.isRequired,
            noAccessReportsDescription: PropTypes.string.isRequired,
            weekFormat: PropTypes.string.isRequired,
        }).isRequired,
    }

    render() {
        const {
            id,
            hide,
            reports,
            labels,
        } = this.props

        const open = reports.filter(openReportsFilter)
        const closed = reports.filter(closedReportsFilter)
        const noaccess = reports.filter(noAccessReportsFilter)

        return (
            <FilterTableItem
                centered={false}
                className={css.item}
                colSpan={3}
                hide={hide}
                id={id}
                name={"country"}
                type="weekmonthstatistic"
            >
                {open.length > 0 && (
                    <ul className={css.reports}>
                        {open.map(report => (
                            <li key={report.id}>
                                <StatisticLink
                                    className={css.report}
                                    {...report}
                                />
                            </li>
                        ))}
                    </ul>
                )}
                {closed.length > 0 && (
                    <div>
                        <h4 className={css.title}>{labels.closedReports} <span>{labels.closedReportsDescription}</span></h4>
                        <ul className={css.reports}>
                            {closed.map(report => (
                                <li key={report.id}>
                                    <StatisticLink
                                        className={css.report}
                                        {...report}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {noaccess.length > 0 && (
                    <div>
                        <h4 className={css.title}>{labels.closedReports}</h4>
                        <div
                            className={cn('wysiwyg', 'negative', css.noAccessReportsDescription)}
                            dangerouslySetInnerHTML={{__html: labels.noAccessReportsDescription}}
                        />
                        <ul className={css.reports}>
                            {noaccess.map(report => (
                                <li key={report.id}>
                                    <StatisticLink
                                        className={css.report}
                                        {...report}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </FilterTableItem>
        )
    }
}
