/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './ScrollToLink.scss'
import Icon from 'components/Icon'
import { scrollToBlock } from '../../utils';

const ScrollToLink = ({
    className,
    text,
    targetBlockId,
    useTopMenuOffset = true,
    updateHashOnScroll = false
}) => {
    const onScrollToLinkClick = (e) => {
        e.preventDefault();

        scrollToBlock(targetBlockId, useTopMenuOffset);

        if (updateHashOnScroll) {
            history.pushState(null, null, `#${targetBlockId}`);
        }
    };

    return (
        <a
            href={`#${targetBlockId}`}
            className={cn('scroll-to-link', className)}
            onClick={onScrollToLinkClick}
        >
            {text && <span className={cn('scroll-to-link__text')}>{text}</span>}
            <Icon use="arrow-down" className="scroll-to-link__icon" />
        </a>
    )
}

ScrollToLink.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    targetBlockId: PropTypes.string,
    useTopMenuOffset: PropTypes.bool
}

ScrollToLink.meta = 'exclude'

export default ScrollToLink
