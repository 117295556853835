import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import ResponsiveImage from 'components/ResponsiveImage'
import Anchor from 'components/Anchor'
import './PrioritizedMarketsBlock.scss'

const PrioritizedMarketsBlock = ({ heading, headingTagLevel = 3, intro, link, image, anchor, placeholderImageAltText }) => {
    const HeadingTag = `h${headingTagLevel}`;

    return (
        <article className="prioritized-markets-block">
            <div className="prioritized-markets-block__card card">
                <div className="prioritized-markets-block__text-content content">
                    <header>
                        {heading && (
                            <HeadingTag className="prioritized-markets-block__heading">
                                {anchor && <Anchor {...anchor} />}
                                {heading}
                            </HeadingTag>
                        )}
                        {intro && (
                            <div
                                className="prioritized-markets-block__intro intro wysiwyg typo-small"
                                dangerouslySetInnerHTML={{
                                    __html: intro.stripHtmlTags()
                                }}
                            />
                        )}
                        {link && (
                            <Link
                                {...link}
                                className="prioritized-markets-block__link shortcut"
                            />
                        )}
                    </header>
                </div>
                <div className="image">
                    {image && image.src ?
                        <ResponsiveImage
                            {...image}
                            className="responsive-image_size_prioritized-markets-block"
                            fluid
                        />:
                        <ResponsiveImage
                            src="/Seafood/Static/img/image-placeholder-light.svg"
                            alt={placeholderImageAltText || ""}
                            className="responsive-image_size_prioritized-markets-block"
                            fluid
                        />
                    }
                </div>
            </div>
        </article>
    )
}

PrioritizedMarketsBlock.propTypes = {
    anchor: PropTypes.exact(Anchor.propTypes),
    heading: PropTypes.string,
    headingTagLevel: PropTypes.oneOf([2, 3]),
    intro: PropTypes.string,
    link: PropTypes.exact(Link.propTypes),
    image: PropTypes.exact(ResponsiveImage.propTypes),
    placeholderImageAltText: PropTypes.string
}

export default PrioritizedMarketsBlock
