import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';

import css from './Icon.scss'

const ICONS = {
    caret: ['polygon', 16, 16, '14.305 2.99943 8.113 8.16043 1.921 2.99943 0 5.30443 8.113 12.06543 16.226 5.30443'],
    close: ['path', 32, 32, 'M14,16 L1.26898492e-13,30 L2,32 L16,18 L30,32 L32,30 L18,16 L32,2 L30,-7.10542736e-15 L16,14 L2,-8.10462808e-15 L1.28341782e-13,2 L14,16 Z'],
    search: ['path', 25, 25, 'M6,0 L1,5 L1,12.97 L6,18 L13.999,18 L15.499,16.501 L24,25 L24,21.001 L17.499,14.5 L19,13.001 L19,5 L13.999,0 L6,0 Z M3.999,11.731 L3.999,6.241 L7.239,3.001 L12.88,3.001 L16,6.241 L16,11.76 L12.76,15 L7.249,15 L3.999,11.731 Z']
}

const Icon = ({ className, onClick, icon, use, size }) => {
    if(use) {
        return (
            <svg className={className} focusable="false" onClick={onClick}>
                <use
                    xlinkHref={`#icon-${use}`}
                />
            </svg>
        )
    }

    return (
        <svg
            className={cn(className, {
                [css.clickable]: onClick
            })}
            height={size}
            onClick={onClick}
            viewBox={`0 0 ${ICONS[icon][1]} ${ICONS[icon][2]}`}
            width={size}
        >
            <title>{icon}</title>
            {ICONS[icon][0] === 'polygon' && <polygon points={ICONS[icon][3]} />}
            {ICONS[icon][0] === 'path' && <g><path d={ICONS[icon][3]} /></g>}
        </svg>
    )
}

Icon.defaultProps = {
    color: '#fff',
    size: 32
}

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.keys(ICONS)),
    onClick: PropTypes.func,
    use: PropTypes.string,
    size: PropTypes.number.isRequired
}

export default Icon
