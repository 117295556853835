import React from 'react'
import PropTypes from 'prop-types'

import FilterList from 'hoc/FilterList'
import FilterTable from 'components/FilterTable'
import Exporter from 'components/Exporter'
import Species from 'components/Species'

import ExporterType from 'types/ExporterType'

class ExporterRegistry extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(ExporterType)).isRequired,
        labels: PropTypes.shape({
            countries: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            species: PropTypes.string.isRequired,
            resultsOnFormatLabel: PropTypes.string,
            resultsCountFormatLabel: PropTypes.string
        }).isRequired,
        listHeading: PropTypes.string,
        shown: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        onToggle: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired,
        searchValue: PropTypes.string
    }

    static defaultProps = {
        labels: {
            resultsCountFormatLabel: "{0} treff",
            resultsOnFormatLabel: "på {0}"
        }
    }

    render() {
        const { items, labels, shown, searchValue, listHeading, totalItems } = this.props

        return (
            <FilterTable
                component={Exporter}
                headers={[
                    labels.name,
                    labels.species,
                ]}
                items={items}
                labels={labels}
                onToggle={this.props.onToggle}
                rows={[
                    item => item.name,
                    item => <Species species={item.species} />,
                ]}
                shown={shown}
                type="exporter"
                searchValue={searchValue}
                caption={listHeading}
                totalItems={totalItems}
            />
        )
    }
}

export default FilterList(ExporterRegistry)
