import PropTypes from 'prop-types'
import './CountryPageWebinarsBlock.scss'
import ResponsiveImage from 'components/ResponsiveImage'

const CountryPageWebinarsBlockItem = {};

CountryPageWebinarsBlockItem.propTypes = {
    heading: PropTypes.string,
    startDate: {
        dateAndTime: PropTypes.string,
        dateAndTimeFormatted: PropTypes.string,
    },
    endDate: {
        dateAndTime: PropTypes.string,
        dateAndTimeFormatted: PropTypes.string,
    },
    location: PropTypes.string,
    introHtml: PropTypes.string,
    videoUrl: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    url: PropTypes.string
}

export default CountryPageWebinarsBlockItem