const classNames = [
    'article',
    'list-page'
]

const footer = document.querySelector('footer.main-footer')

const checkFooterColor = () => {
    const hasRelated = document.querySelector('main .list-items')

    if(hasRelated) {
        if(! footer.classList || !footer.classList.add) {
            return console.error('Element.classList.add not supported')
        }
        footer.classList.add('gray-footer')
    }
}

function footerFrontpage() {
    const lastChild = document.querySelector('main > :last-child')

    if(lastChild) {
        const { backgroundColor } = window.getComputedStyle(lastChild)

        if(backgroundColor !== 'rgb(255, 255, 255)') {
            footer.classList.add('gray-footer')
        }
    }
}

window.addEventListener('DOMContentLoaded', () => {
    if(! classNames.some) {
        return console.error('Array.some not supported')
    }

    if(classNames.some(cn => document.body.classList.contains(cn))) {
        checkFooterColor()
    }

    if(document.body.classList.contains('frontpage')) {
        footerFrontpage()
    }
})
