import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Icon from 'components/Icon';
import cn from 'classnames';
import './CountryPageLinkButton.scss';

const properties = Object.assign({}, Link.propTypes, {
    iconName: PropTypes.string
});

const CountryPageLinkButton = props => {
    return (
        <Link {...props} className={cn("country-page-link-button", props.className)}>
            <span className="country-page-link-button__text">
                {props.text}
            </span>
            {props.iconName && <Icon use={props.iconName}  className="country-page-link-button__icon" />}
        </Link>
    );
}

CountryPageLinkButton.propTypes = properties.propTypes;

CountryPageLinkButton.propTypesMeta = 'exclude';

export default CountryPageLinkButton;