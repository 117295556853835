import React from 'react'
import PoorText from 'components/PoorText'
import Link from 'components/Link'
import ResponsiveImage from 'components/ResponsiveImage'
import LinkWithIcon from 'components/LinkWithIcon'
import PropTypes from 'prop-types'

import './MarketInsightLatestListItem.scss'

const MarketInsightLatestListItem = ({ link, image, isRestricted, introHtml, publishedAt, publishedAtFormatted, publishedLabel }) => (
    <a 
        href={link.url} 
        className='market-insight-latest-list-item'
        target={link.openInNewTab ? '_blank' : null}
        rel={link.openInNewTab ? 'noopener noreferrer' : null}  
    >
        {image && 
            <ResponsiveImage
                {...image}
                fluid={true}
                className="market-insight-latest-list-item__image responsive-image_size_market-insight-latest-list-item"
            />
        }
        {link.text && 
            <LinkWithIcon
                text={link.text}
                openInNewTab
                className='market-insight-latest-list-item__heading'
                hideIcon={!isRestricted}
                type={
                    isRestricted
                        ? LinkWithIcon.types.restricted
                        : LinkWithIcon.types.regular
                }
            />
        }
        {publishedAt && <time className='market-insight-latest-list-item__time' dateTime={publishedAt}>{publishedLabel}: {publishedAtFormatted}</time>}
        {introHtml && 
            <PoorText 
                className='poor-text_in-market-insight-latest-list-item' 
                text={introHtml} 
            />
        }
    </a>
);

MarketInsightLatestListItem.propTypesMeta = {
    publishedLabel: 'exclude',
}

MarketInsightLatestListItem.propTypes = {
    introHtml: PropTypes.string,
    publishedAt: PropTypes.string,
    publishedAtFormatted: PropTypes.string,
    link: PropTypes.exact(Link.propTypes).isRequired,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    isRestricted: PropTypes.bool
}

export default MarketInsightLatestListItem;