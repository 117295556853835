import PropTypes from 'prop-types'

import CategoryType from 'types/CategoryType'
import AttachmentType from 'types/AttachmentType'
import EmployeeType from 'types/EmployeeType'

const ReportType = {
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    image: PropTypes.shape({
        url: PropTypes.string,
        focusPoint: PropTypes.object
    }),
    intro: PropTypes.string,
    employees: PropTypes.arrayOf(PropTypes.shape(EmployeeType)).isRequired,
    attachment: PropTypes.shape(AttachmentType),
    species: PropTypes.arrayOf(PropTypes.shape(CategoryType)),
    countries: PropTypes.arrayOf(PropTypes.shape(CategoryType))
}

export default ReportType
