import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import DownloadLink from 'components/DownloadLink'
import cn from 'classnames'
import ResourceLinkType from 'types/ResourceLinkType'

const MarketInsightDownloadableStatisticsBlockItem = ({
    category,
    isRestricted,
    pdfLinks,
    excelLinks,
    pdfLabel,
    excelLabel,
    className
}) => {
    return (
        <div className={cn('market-insight-downloadable-statistics-block-item', className)}>
            <div className='market-insight-downloadable-statistics-block-item__heading-row'>
                {category && <h3 className='market-insight-downloadable-statistics-block-item__heading'>{category}</h3>}
                {isRestricted && <Icon use='lock' className='market-insight-downloadable-statistics-block-item__icon' />}
            </div>
            <div className='market-insight-downloadable-statistics-block-item__columns'>
                {pdfLinks && pdfLinks.length > 0 ? (
                    <div className='market-insight-downloadable-statistics-block-item__column'>
                        {pdfLinks.map((link, index) => (
                            <div key={index} className='market-insight-downloadable-statistics-block-item__link-item'>
                                <DownloadLink 
                                    {...link}
                                    text={`${pdfLabel || ''}${link.size ? ' (' + link.size + ')' : ''}`}
                                    iconName="pdf-doc"
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
                {excelLinks && excelLinks.length > 0 ? (
                    <div className='market-insight-downloadable-statistics-block-item__column'>
                        {excelLinks.map((link, index) => (
                            <div key={index} className='market-insight-downloadable-statistics-block-item__link-item'>
                                <DownloadLink 
                                    {...link}
                                    text={`${excelLabel || ''}${link.size ? ' (' + link.size + ')' : ''}`}
                                    iconName="excel-doc"
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

MarketInsightDownloadableStatisticsBlockItem.propTypes = {
    category: PropTypes.string,
    isRestricted: PropTypes.bool.isRequired,
    pdfLinks: PropTypes.arrayOf(PropTypes.exact(ResourceLinkType)),
    excelLinks: PropTypes.arrayOf(PropTypes.exact(ResourceLinkType)),
    pdfLabel: PropTypes.string,
    excelLabel: PropTypes.string,
    className: PropTypes.string
}

MarketInsightDownloadableStatisticsBlockItem.propTypesMeta = {
    pdfLabel: 'exclude',
    excelLabel: 'exclude',
    className: 'exclude'
}

export default MarketInsightDownloadableStatisticsBlockItem