import React from 'react'
import PropTypes from 'prop-types'

import FilterList from 'hoc/FilterList'
import EventCard from 'components/EventCard'

import NoResults from 'components/NoResults'

import EventType from 'types/EventType'

import css from './EventList.scss'

class EventList extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(EventType)).isRequired,
        labels: PropTypes.shape({
            itemsSingular: PropTypes.string.isRequired,
            itemsPlural: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            what: PropTypes.string.isRequired,
            where: PropTypes.string.isRequired,
            eventWasHeld: PropTypes.string.isRequired,
            close: PropTypes.string.isRequired,
        }).isRequired,
        onToggle: PropTypes.func.isRequired,
        totalItems: PropTypes.number.isRequired
    }

    _handleResetClick = e => {
        e.preventDefault()
        window.dispatchEvent(new Event('seafood-filter-reset'))
    }

    render() {
        const { items, labels } = this.props

        return (
            <div>
                {items.length === 0 && (
                    <NoResults 
                        labels={labels}
                        onReset={this._handleResetClick}
                    />
                )}
                <div aria-live="polite" className={css.events}>
                    {items && items.map(i =>
                        <EventCard
                            key={i.id}
                            {...i}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default FilterList(EventList)
