import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './FullPageImageBlock.scss'
import cn from 'classnames'
import BackgroundMedia from 'components/BackgroundMedia'
import imageResizer from 'components/ResponsiveImage/ImageResizer.js'

const FullPageImageBlock = ({ text, mediaClassName, background }) => {
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    useEffect(() => {
        setImageWidth(imageResizer.calcImageWidth(window.screen.width, 100));
        setImageHeight(imageResizer.calcImageHeight(window.screen.height, 100));
    });

    return (
        <div className="full-page-image-block">
            {background && (background.video || background.image) && (
                <BackgroundMedia
                    className={cn({
                        [mediaClassName]: mediaClassName
                    })}
                    {...background}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                />
            )}

            {text && (
                <div className="full-page-image-block__text-container">
                    <div className="full-page-image-block__text">
                        <span className="full-page-image-block__text-background">
                            {text}
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

FullPageImageBlock.propTypes = {
    text: PropTypes.string,
    background: PropTypes.exact(BackgroundMedia.propTypes),
    mediaClassName: PropTypes.string
}

export default FullPageImageBlock
