import PubSub from 'pubsub-js'

const eventNames = {
    reportElementRegistration: 'reportElementRegistration',
    initializationDone: 'initializationDone',
}

const registeredReportElements = [];

function onReportElementRegistration(func) {
    if (typeof func !== 'function') {
        return
    }

    registeredReportElements.forEach((element) => func(element))

    return PubSub.subscribe(
        eventNames.reportElementRegistration,
        (_topic, { reportElement }) => {
            func({ reportElement })
        }
    )
}

function registerReportElement(reportElement) {
    registeredReportElements.push(reportElement);
    PubSub.publish(eventNames.reportElementRegistration, { reportElement })
}

function onInitializationDone(func) {
    if (typeof func !== 'function') {
        return
    }

    return PubSub.subscribe(eventNames.initializationDone, () => {
        func({})
    })
}

function sendinitializationDone() {
    PubSub.publish(eventNames.initializationDone, {})
}

export default {
    onReportElementRegistration,
    registerReportElement,
    onInitializationDone,
    sendinitializationDone,
}
