import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import CountryPageWebinarsBlockItem from './CountryPageWebinarsBlockItem'
import CountryPageWebinarsBlockColumn from './CountryPageWebinarsBlockColumn'
import './CountryPageWebinarsBlock.scss'

const CountryPageWebinarsBlock = ({ webinar, conference, lang, allWebinarsLink, allConferencesLink  }) => {
    return (
        <div className="country-page-webinars-block">
            <div className="country-page-webinars-block__columns">
                {webinar && 
                    <CountryPageWebinarsBlockColumn
                        item={webinar}
                        viewAllLink={allWebinarsLink} 
                        heading={lang.webinarHeading}
                        isSingle={!conference}
                    />
                }
                {conference && 
                    <CountryPageWebinarsBlockColumn
                        item={conference}
                        viewAllLink={allConferencesLink} 
                        heading={lang.conferenceHeading}
                        isSingle={!webinar}
                    />
                }
            </div>
        </div>
    )
}

CountryPageWebinarsBlock.propTypes = {
    webinar: PropTypes.exact(CountryPageWebinarsBlockItem.propTypes),
    conference: PropTypes.exact(CountryPageWebinarsBlockItem.propTypes),
    allWebinarsLink: PropTypes.exact(Link.propTypes),
    allConferencesLink: PropTypes.exact(Link.propTypes),
    lang: PropTypes.shape({
        webinarHeading: PropTypes.string.isRequired,
        conferenceHeading:  PropTypes.string.isRequired,
    }).isRequired
}

export default CountryPageWebinarsBlock
