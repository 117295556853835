import React from 'react'
import PropTypes from 'prop-types'

const getLabel = (label, labels, fallback) => {
    if(! label || ! labels) return fallback

    return labels[label] || fallback
}

const Label = ({
    fallback,
    label,
}, {
    labels
}) => (
    <span>
        {getLabel(label, labels, fallback)}
    </span>
)

Label.propTypes = {
    fallback: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

Label.contextTypes = {
    labels: PropTypes.object
}

export default Label
