import React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import cn from 'classnames'
import breakpoints from 'js/breakpoints';

import ArticleCard from 'components/ArticleCard'

import css from './StatisticsBlock.module.scss'

class StatisticsBlock extends React.PureComponent {
    static propTypes = {
        description: PropTypes.string,
        mobileReportUnavailable: PropTypes.string,
        mobileUrl: PropTypes.string,
        target: PropTypes.string,
        url: PropTypes.string
    }

    state = {
        mobile: false
    }

    componentDidMount() {
        this.checkIsMobile()

        window.addEventListener('resize', this.checkIsMobile)
    }

    checkIsMobile = throttle(() => {
        const isMobile = breakpoints.l(false)

        if (isMobile !== this.state.mobile) {
            this.setState({ mobile: isMobile })
        }
    }, 100)
    

    render() {
        const {
            mobileUrl,
            mobileReportUnavailable,
            target,
            url,
            description,
            ...props
        } = this.props

        const { mobile } = this.state

        const hasNoMobileUrl = mobile && !mobileUrl

        return (
            <a
                className={cn(css.link, {
                    [css.hasNoMobileUrl]: !mobileUrl
                })}
                href={mobile ? mobileUrl : url}
                rel={
                    target === '_blank' && !mobile
                        ? 'noopener noreferrer'
                        : null
                }
                target={!mobile ? target : null}
            >
                <ArticleCard
                    {...props}
                    intro={
                        hasNoMobileUrl ? mobileReportUnavailable : description
                    }
                    headingTagLevel={3}
                />
            </a>
        )
    }
}

export default StatisticsBlock
