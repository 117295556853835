import React from 'react'
import Link from 'components/Link'
import LinkWithIcon from 'components/LinkWithIcon'
import ResponsiveImage from 'components/ResponsiveImage'
import PoorText from 'components/PoorText'
import ReadMore from 'components/ReadMore'
import SimpleTagList from 'components/SimpleTagList'
import PropTypes from 'prop-types'
import cn from 'classnames'

const MarketInsightReportItem = ({
    isRestricted,
    link,
    date,
    dateFormatted,
    image,
    tags,
    introHtml,
    contactPersonLabel,
    contactPerson,
    publishedLabel,
    readMoreLabel,
    readLessLabel
}) => {
    const renderReportTopInfo = (forMobile = false) => {
        return (
            <div className={cn('market-insight-report-item__top-info', {
                'market-insight-report-item__top-info_mobile': forMobile
            })}>
                <div className='market-insight-report-item__heading'>
                    <LinkWithIcon
                        {...link}
                        size={LinkWithIcon.sizes.large}
                        darkMode={true}
                        type={
                            isRestricted
                                ? LinkWithIcon.types.restricted
                                : LinkWithIcon.types.download
                        }
                    />
                </div>
                {date && <time className='market-insight-report-item__date' dateTime={date}><span className='hidden-desktop'>{publishedLabel}: </span>{dateFormatted}</time>}
                <SimpleTagList items={tags} className='market-insight-report-item__tags' />
            </div>
        )
    }

    return (
        <div className='market-insight-report-item'>
            <div className='market-insight-report-item__image'>
                <a href={link.url} className='market-insight-report-item__image-link' target={link.openInNewTab ? '_blank' : null}>
                    <ResponsiveImage 
                        {...image} 
                        fluid={true}
                        className="responsive-image_size_market-insight-report-item" 
                    />
                </a>
            </div>
            {renderReportTopInfo(true)}
            <div className='market-insight-report-item__content'>
                {renderReportTopInfo()}
                {
                    (introHtml || contactPerson) && (
                        <div className='market-insight-report-item__details'>
                            {introHtml && (
                               <ReadMore 
                                    className='market-insight-report-item__intro'
                                    readMoreText={readMoreLabel}
                                    readLessText={readLessLabel}
                                    destroyOnMobile={true}
                                >
                                    <PoorText text={introHtml} className='poor-text_in-market-insight-report-item' />
                                </ReadMore>
                            )}
                            {contactPerson && (
                                <div className='market-insight-report-item__contact-person'>
                                    <span>{contactPersonLabel}: <a className='market-insight-report-item__link' href={`mailto:${contactPerson.email}`}>{contactPerson.name}</a> , {contactPerson.jobTitle} , {contactPerson.country}</span>
                                </div>
                            )}
                        </div>
                    )
                }
                
            </div>
        </div>
    )
}

MarketInsightReportItem.propTypes = {
    id: PropTypes.number,
    isRestricted: PropTypes.bool,
    link: PropTypes.exact(Link.propTypes),
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    tags: PropTypes.arrayOf(PropTypes.string),
    introHtml: PropTypes.string,
    contactPersonLabel: PropTypes.string,
    publishedLabel: PropTypes.string,
    readMoreLabel: PropTypes.string,
    readLessLabel: PropTypes.string,
    contactPerson: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        jobTitle: PropTypes.string,
        country: PropTypes.string
    })
}

MarketInsightReportItem.propTypesMeta = {
    contactPersonLabel: 'exclude',
    publishedLabel: 'exclude',
    readMoreLabel: 'exclude',
    readLessLabel: 'exclude'
}

export default MarketInsightReportItem
