import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import BackgroundVideo from 'components/BackgroundVideo'
import ResponsiveImage from 'components/ResponsiveImage'
import './BackgroundMedia.scss'
import Photographer from 'components/Photographer'

const BackgroundMedia = ({ image, video, className, photographer, photographerClassName, imageHeight, imageWidth }) => {
    return video || image ? (
        <div className={cn('background-media', className)}>
            {video && video.url ? (
                <BackgroundVideo
                    {...video}
                    className="background-media__video"
                />
            ) : (
                <React.Fragment>
                    {image && (
                        <ResponsiveImage
                            {...image}
                            fluid={true}
                            width={imageWidth}
                            height={imageHeight}
                            className="background-media__image"
                        />
                    )}
                </React.Fragment>
            )}
            {photographer && <Photographer className={photographerClassName} {...photographer} />}
        </div>
    ) : null
}

BackgroundMedia.propTypes = {
    photographer: PropTypes.exact(Photographer.propTypes),
    photographerClassName: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    video: PropTypes.exact(BackgroundVideo.propTypes),
    className: PropTypes.string,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
}

BackgroundMedia.propTypesMeta = {
    imageHeight: 'exclude',
    imageWidth: 'exclude',
}

export default BackgroundMedia
