import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import css from './StepIndicator.module.scss'

function stepsToArray(steps) {
    const array = []
    for(let i = 1; i <= steps; i++) array.push(i)
    return array
}

const StepIndicator = ({
    className,
    step,
    steps,
}) => (
    <div className={cn(css.steps, className)}>
        {stepsToArray(steps).map(s => (
            <small
                className={cn(css.step, {
                    [css.active]: s === step
                })}
                key={s}
            >{s}</small>
        ))}
    </div>
)

StepIndicator.propTypes = {
    className: PropTypes.string,
    step: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
}

export default StepIndicator
