

import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ResponsiveImage from 'components/ResponsiveImage'
import EmbeddedVideo from 'components/EmbeddedVideo'
import Modal, { modalType } from 'components/Modal'
import imageResizer from 'components/ResponsiveImage/ImageResizer.js';
import css from './TopMediaBlock.scss'

const TopMediaBlock = ({image, videoUrl, imageCaption}) => {
    const [modalOpened, setModalOpened] = useState(false)
    const bannerContainerRef = useRef();
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        setImageWidth(imageResizer.calcImageWidth(Math.min(1440, window.screen.width), 100));
    });

    const openModal = () => {
        setModalOpened(true)
    }

    const closeModal = () => {
        setModalOpened(false)
    }

    return (
        <div className={css.topMediaBlock} ref={bannerContainerRef}>
            <div className={css.topMediaBlockHolder}>
                {videoUrl && 
                    <Fragment>
                        <button className={css.topMediaBlockBtn} onClick={openModal}></button>
                        {modalOpened &&
                            <Modal 
                                onClose={closeModal}  
                                type={modalType.video}
                            >
                                <EmbeddedVideo videoUrl={videoUrl} />
                            </Modal>
                        }
                    </Fragment>
                }
                {image &&
                    <Fragment>
                    <meta itemProp="image" content={image.src} />
                    <ResponsiveImage 
                        {...image} 
                        fluid={true}
                        width={imageWidth}
                        transform="fill"
                    />
                    </Fragment>
                    
                }
            </div>
            {imageCaption && 
                <span className={css.bannerImageCaption}>{imageCaption}</span>
            }
        </div>
    );
};

TopMediaBlock.propTypes = {
    image: PropTypes.exact(ResponsiveImage.propTypes),
    videoUrl: PropTypes.string,
    imageCaption: PropTypes.string
};

export default TopMediaBlock;
