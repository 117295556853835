import React from 'react'
import PropTypes from 'prop-types'
import './CountryPageWebinarsBlock.scss'
import EmbeddedVideo from 'components/EmbeddedVideo'
import ResponsiveImage from 'components/ResponsiveImage'
import RichText from 'components/RichText'
import Link from 'components/Link'
import LinkWithIcon from 'components/LinkWithIcon'
import cn from 'classnames'
import CountryPageWebinarsBlockItem from './CountryPageWebinarsBlockItem'

const CountryPageWebinarsBlockColumn = ({ 
    heading, 
    viewAllLink,
    item,
    isSingle=false
  }) => {
   
    return (
        <div className={cn("country-page-webinars-block__column", {
            "country-page-webinars-block__column_single": isSingle
        })}>
            <div className="country-page-webinars-block__column-group">
                {heading && <h2 className="country-page-webinars-block__heading">{heading}</h2>}
                {item.videoUrl ? 
                    <EmbeddedVideo 
                        videoUrl={item.videoUrl} 
                        playing={false}
                        controls={true}
                        className="country-page-webinars-block__item-video"
                    />
                    : item.image && <ResponsiveImage {...item.image} className="country-page-webinars-block__item-image responsive-image_size_country-page-webinars-block" />}
            </div>
            <div className="country-page-webinars-block__column-group">
                {heading && <h3 className="country-page-webinars-block__item-heading">
                    {item.url ? <a href={item.url} className="country-page-webinars-block__item-link">{heading}</a>: heading}
                    
                </h3>}
                <div className="country-page-webinars-block__item-date">
                    {item.startDate && <time dateTime={item.startDate.dateAndTime}>{item.startDate.dateAndTimeFormatted}</time>}
                    {item.endDate && 
                    <React.Fragment>
                        {item.startDate && <span aria-hidden="true"> - </span>}
                        <time dateTime={item.endDate.dateAndTime}>{item.endDate.dateAndTimeFormatted}</time>
                    </React.Fragment>
                    }
                </div>
                {item.location && <div className="country-page-webinars-block__item-location">{item.location}</div>}
                {item.introHtml && <RichText className="country-page-webinars-block__item-intro rich-text_in-country-page-webinars-block" text={item.introHtml} />}
                <LinkWithIcon {...viewAllLink} className="country-page-webinars-block__see-all-link" />
            </div>
        </div>
    )
}

CountryPageWebinarsBlockColumn.propTypes = {
    heading: PropTypes.string,
    viewAllLink: PropTypes.exact(Link.propTypes),
    isSingle: PropTypes.bool,
    item: PropTypes.exact(CountryPageWebinarsBlockItem.propTypes),
}

CountryPageWebinarsBlockColumn.propTypesMeta = 'exclude'

export default CountryPageWebinarsBlockColumn
