import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import PoorText from 'components/PoorText'
import LinkWithIcon from 'components/LinkWithIcon'
import MarketInsightLatestListItem from 'components/MarketInsightLatestListItem'

import './MarketInsightLastPublications.scss'

const MarketInsightLastPublications = ({
    heading,
    introHtml,
    items,
    earlierPublicationsLink,
    publishedLabel
}) => (
    <div className='market-insight-last-publications'>
        {heading && <h3 className='market-insight-last-publications__heading'>{heading}</h3>}
        {introHtml && <PoorText text={introHtml} className='poor-text_in-market-insight-last-publications' />}
        {items && items.length > 0 && <div className='market-insight-last-publications__list'>
            {items.map((item, index) => (
                <div className='market-insight-last-publications__item' key={index}>
                    <MarketInsightLatestListItem {...item} publishedLabel={publishedLabel} />
                </div>
            ))}
        </div>}
        {earlierPublicationsLink && 
            <div className='market-insight-last-publications__link-holder'>
                <LinkWithIcon
                    {...earlierPublicationsLink}
                    className='market-insight-last-publications__link'
                />
            </div>
        }
    </div>
)

MarketInsightLastPublications.propTypesMeta = {
    publishedLabel: 'exclude',
}

MarketInsightLastPublications.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact(MarketInsightLatestListItem.propTypes)),
    earlierPublicationsLink: PropTypes.exact(Link.propTypes),
    publishedLabel: PropTypes.string
}

export default MarketInsightLastPublications