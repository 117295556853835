import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import css from './SocialMediaLinksBlock.scss'

const SocialMediaLinksBlock = ({title, items}) => {
    return (
        <div className={css.socialMediaLinksBlock}>
            <div className={css.socialMediaLinksWrapper}>
                {title && <h2 className={cn(css.socialMediaListTitle, 'h4')}>{title}</h2>}
                {items && items.length > 0 && 
                    <ul className={css.socialMediaList}>
                        {items.map((item, index) => (
                            <li className={css.socialMediaListItem} key={index}>
                                <a href={item.link} target="_blank" className={css.socialMediaLink} title={item.linkText}>
                                    {item.icon && <img src={item.icon} alt={item.iconAltText} className={css.socialMediaLinkIcon} />}
                                    {item.linkText && <span className={css.socialMediaLinkText}>{item.linkText}</span>}
                                </a>
                            </li>
                        ))}    
                    </ul>
                }
            </div>
        </div>
    );
};

SocialMediaLinksBlock.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        iconAltText: PropTypes.string,
        link: PropTypes.string,
        linkText: PropTypes.string
    }))
};

export default SocialMediaLinksBlock;