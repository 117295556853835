import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from 'components/Icon'
import Loader from 'components/Loader'

const SearchWrapper = ({
    autoComplete,
    buttonLabel,
    className,
    defaultValue,
    id,
    name,
    negative,
    onChange,
    onClick,
    placeholder,
    searching,
    value,
    ariaLabel
}) => {
    return (
        <div className={cn('search-wrapper', {
            'negative': negative
        }, className)}>
            <input
                autoComplete={autoComplete}
                className="no-label input"
                defaultValue={defaultValue}
                id={id}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                type="search"
                value={value}
                aria-label={ariaLabel}
            />
            <button
                onClick={onClick}
                type="submit"
            >
                <span>{buttonLabel}</span>
                {!searching && <Icon icon="search" size={25} />}
                {searching && <Loader />}
            </button>
        </div>
    )
}

SearchWrapper.defaultProps = {
    autoComplete: "off",
    name: "search",
    negative: false,
    searching: false
}

SearchWrapper.propTypes = {
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    negative: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    searching: PropTypes.bool.isRequired,
    value: PropTypes.string,
    ariaLabel: PropTypes.string
}

export default SearchWrapper
