import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FluidImage from 'components/FluidImage'
import Icon from 'components/Icon'

const ArticleCard = ({
    id,
    categories,
    className,
    hasAccess,
    hasAccessLabel,
    intro,
    image,
    isNewsPage,
    published,
    publishedFormatted,
    title,
    headingTagLevel = 3,
}) => {
    const HeadingTag = `h${headingTagLevel}`;

    return (
        <article
            className={cn('card', className, { news: isNewsPage })}
            data-id={id}
        >
            <div className="content">
                <header>
                    <HeadingTag className='h2'>
                        {!hasAccess && <Icon use="key" />}
                        {title}
                    </HeadingTag>
                    {intro && (
                        <div
                            className="intro wysiwyg typo-small"
                            dangerouslySetInnerHTML={{
                                __html: intro.stripHtmlTags()
                            }}
                        />
                    )}
                </header>
                {(published || publishedFormatted || categories) && (
                    <div className="meta typo-small">
                        {published &&
                            publishedFormatted && (
                                <time dateTime={published}>
                                    {publishedFormatted}
                                </time>
                            )}
                        {categories && (
                            <span className="categories">{categories}</span>
                        )}
                    </div>
                )}
            </div>
            <div className="image">
                <FluidImage
                    {...image}
                    fallback="/Seafood/Static/img/image-placeholder-light.svg"
                    size={330}
                />
                {!hasAccess && (
                    <div className="no-access">
                        <Icon use="key" />
                        <span
                            dangerouslySetInnerHTML={{ __html: hasAccessLabel }}
                        />
                    </div>
                )}
            </div>
        </article>
    )
}

ArticleCard.defaultProps = {
    isNewsPage: false
}

ArticleCard.propTypes = {
    id: PropTypes.number,
    categories: PropTypes.string,
    className: PropTypes.string,
    hasAccess: PropTypes.bool.isRequired,
    hasAccessLabel: PropTypes.string.isRequired,
    image: PropTypes.exact(FluidImage.propTypes),
    intro: PropTypes.string,
    isNewsPage: PropTypes.bool.isRequired,
    published: PropTypes.string,
    publishedFormatted: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string, // TODO:  Please note that link to article is out of ArticleCard itself(Legacy сode) and rendered in list components:  LatestArticlesBlock, InternationalMediaBlock, SearchResults, NewsFolder, StatisticsBlock
    headingTagLevel: PropTypes.oneOf([2, 3]),
}

export default ArticleCard
