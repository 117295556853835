import PropTypes from 'prop-types'

const MarketplaceRequestType = {
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    contactPerson: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    fax: PropTypes.string,
    country: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    species: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })),
    regions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }))
}

export default MarketplaceRequestType
