import React from 'react'
import Icon from 'components/Icon'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import cn from 'classnames'

const MarketInsightCtaLink = ({ heading, link, isRestricted }) => {
    const RootElement = link && link.url ? 'a' : 'div';

    const rootElementProps = {
      ...(link && {
        href: link.url,
        target: link.openInNewTab ? '_blank' : null,
        rel: link.openInNewTab ? 'noopener noreferrer' : null
      }),
    };

    return (
        <RootElement 
            {...rootElementProps}
            className={cn('market-insight-cta-link', { 'market-insight-cta-link_restricted': isRestricted })}
        >
            {heading && 
                <h3 className='market-insight-cta-link__heading'>
                    <span className='market-insight-cta-link__heading-text'>{heading}</span>
                    {isRestricted && <Icon className="market-insight-cta-link__icon market-insight-cta-link__heading-icon" use="lock" />}
                </h3>
            }
            {link && link.text && 
                <span className='market-insight-cta-link__text'>{link.text}</span>
            }
        </RootElement>
    )
};

MarketInsightCtaLink.propTypes = {
    heading: PropTypes.string,
    componentName: PropTypes.string,
    isRestricted: PropTypes.bool,
    link: PropTypes.exact(Link.propTypes)
}

export default MarketInsightCtaLink;