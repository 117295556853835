import React from 'react'
import PropTypes from 'prop-types'

import FilterTableRow from 'components/FilterTableRow'
import NoResults from 'components/NoResults'

import css from './FilterTable.scss'
import cn from 'classnames'
import Icon from 'components/Icon'

import { replaceStringPlaceholders } from 'js/string-helper'

const _handleResetClick = e => {
    e.preventDefault()
    window.dispatchEvent(new Event('seafood-filter-reset'))
}

function hasHeaders(headers) {
    if (!Array.isArray(headers)) {
        return console.error(headers, 'is not an array')
    }
    return headers.filter(h => h).length > 0
}

function spreadComponentProps(component) {
    if (typeof component === 'object') return component.props

    return null
}

const FilterTable = ({
    classNames,
    component,
    headers,
    items,
    labels = {
        sortByFormatAriaLabel: "Sorter etter {0}",
        resultsTableAriaLabel: "Tabell med resultater",
    },
    name,
    onToggle,
    rows,
    shown,
    type,
    sortable,
    searchValue,
    totalItems,
    caption,
    onSort,
}) => (
    <React.Fragment>
        {labels.resultsCountFormatLabel && (
            <h2 
                {...(items && items.length === 0 && { "aria-describedby": "no-results-container" })}
                className={css.resultsCountText}
            >
                {replaceStringPlaceholders(labels.resultsCountFormatLabel, totalItems)}&nbsp;
                {searchValue && labels.resultsOnFormatLabel ? replaceStringPlaceholders(labels.resultsOnFormatLabel, searchValue) : null}
            </h2>
        )}
        <table className={css.table}>
        {caption && <caption className={css.caption}>{caption}</caption>}
            {hasHeaders(headers) && (
                <thead>
                    <tr>
                        {headers.map((h, i )=> <th key={i} scope='col'>
                            <React.Fragment>
                                {sortable ? 
                                <button 
                                    className={cn(css.sortButton, { 
                                        [css.ascending]: h.direction === 'ascending',
                                        [css.descending]: h.direction === 'descending',
                                    })}
                                    aria-label={replaceStringPlaceholders(
                                        labels.sortByFormatAriaLabel,
                                        h.text
                                    )}
                                    onClick={e => {
                                        e.preventDefault();
                                        onSort(h.sortKey);
                                    }}
                                >
                                    {h.text && <span className={css.sortButtonText}>{h.text}</span>}
                                    <span className={css.sortButtonIconContainer}>
                                        {h.direction &&<Icon className={css.sortButtonIcon} icon="caret" size={16}/>}
                                    </span>
                                </button>
                                :
                                <React.Fragment>{h}</React.Fragment>
                                }
                            </React.Fragment></th>)}
                    </tr>
                </thead>
                )}
                <tbody aria-live="polite" aria-label={labels.resultsTableAriaLabel} aria-atomic="true">
                    {items &&
                        items.length === 0 && (
                            <tr className={css.noresults}>
                                <td colSpan={headers.length}>
                                    <NoResults
                                        labels={labels}
                                        onReset={_handleResetClick}
                                    />
                                </td>
                            </tr>
                        )}
                    {items.map(i => [
                        <FilterTableRow
                            className={classNames && classNames.row}
                            hidden={shown != i.id}
                            id={i.id}
                            key={`row_${i.id}`}
                            name={name && name(i)}
                            onToggle={onToggle}
                        rows={rows.map(row => row(i))}
                        type={type}
                    />,
                    React.createElement(
                        typeof component === 'object'
                            ? component.component
                            : component,
                        {
                            hide: shown != i.id,
                            key: i.id,
                            labels,
                            name: name && name(i),
                            ...spreadComponentProps(component),
                            ...i
                        }
                    )
                ])}
            </tbody>
        </table>
    </React.Fragment>
)

FilterTable.defaultProps = {
    classNames: {},
    sortable: false,
    onSort: () => {}
}

FilterTable.propTypes = {
    classNames: PropTypes.shape({
        row: PropTypes.string
    }).isRequired,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        .isRequired,
    headers: PropTypes.array,
    items: PropTypes.array.isRequired,
    labels: PropTypes.object.isRequired,
    name: PropTypes.func,
    onToggle: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    caption: PropTypes.string,
    shown: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    searchValue: PropTypes.string,
    totalItems: PropTypes.number.isRequired,
    onSort: PropTypes.func
}

export default FilterTable
