import React from 'react'
import MarketInsightUsefulPagesBlockItem from './MarketInsightUsefulPagesBlockItem'
import PropTypes from 'prop-types'

import './MarketInsightUsefulPagesBlock.scss'

const MarketInsightUsefulPagesBlock = ({ items }) => {
    return items && items.length > 0 ? (
        <div className="marketInsight-useful-pages-block">
            {items.map((item, index) => {
                return <MarketInsightUsefulPagesBlockItem {...item} key={index} />
            })}
        </div>
    ) : null
}

MarketInsightUsefulPagesBlock.propTypes = {
    items: PropTypes.arrayOf(PropTypes.exact(MarketInsightUsefulPagesBlockItem.propTypes))
}

export default MarketInsightUsefulPagesBlock
