import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import LinkButton from 'components/LinkButton'
import PoorText from 'components/PoorText'
import Icon from 'components/Icon'
import './CustomsQuotasCTABlock.scss'

const CustomsQuotasCTABlock = ({
    heading,
    introHtml,
    ctaLink,
    errorMessage
}) => {
    return (
        <div className='customs-quotas-cta-block'>
            <div className='customs-quotas-cta-block__container'>
                <div className='customs-quotas-cta-block__content'>
                    <div className='customs-quotas-cta-block__heading-holder'>
                        {heading && <h2 className='customs-quotas-cta-block__heading'>{heading}</h2>}
                        <Icon use="lock-outlined" className='customs-quotas-cta-block__heading-icon' />
                    </div>
                    {introHtml && <PoorText text={introHtml} className='customs-quotas-cta-block__intro poor-text_in-customs-quotas-cta-block' />}
                    <div className='customs-quotas-cta-block__actions'>
                        {ctaLink && 
                            <LinkButton 
                                {...ctaLink} 
                            />
                        }
                        {errorMessage && <p className='customs-quotas-cta-block__error-msg'>{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

CustomsQuotasCTABlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    ctaLink: PropTypes.exact(Link.propTypes),
    errorMessage: PropTypes.string,
}

export default CustomsQuotasCTABlock
