import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from 'components/Icon'

import { toggleScrollLock, trapTabKey } from '../../utils'

import css from './Video.scss'

class Video extends React.Component {
    static propTypes = {
        buttons: PropTypes.arrayOf(PropTypes.object).isRequired
    }

    state = {
        source: null,
        embedUrl: null
    }

    constructor(props) {
        super(props)

        const { buttons } = props

        if(buttons && buttons.length) {
            buttons.forEach(button => { button.onclick = this._handleClick })
        }
    }

    componentDidMount() {
        if(document) {
            const meta = document.querySelector('meta[itemprop="name"]')
            if(meta && meta.content) {
                this.setState({ title: meta.content })
            }
        }

        if(window) {
            this.setState({ url: window.location })
        }
    }

    componentDidUpdate() {
        const modal = document.getElementById('video-modal')

        if(modal) {
            window.addEventListener('keydown', this._onKeydown)
            modal.focus()
        } else {
            window.removeEventListener('keydown', this._onKeydown)
        }
    }

    _onKeydown = (e) => {
        if (e.which === 27) {
            // Close the modal and set focus on the element which opened the modal..
            // TODO: "Cache" previously focused element
            this._close(e)
        }
        else {
            trapTabKey(document.getElementById('video-modal'), e)
        }
    }

    _handleClick = (e) => {
        const { source, embedUrl } = e.currentTarget.dataset

        toggleScrollLock()

        this.setState({
            source,
            embedUrl
        })
    }
    
    _close = () => {
        toggleScrollLock()
        this.setState({ source: null, embedUrl: null })
    }

    renderEmbed() {
        const { source } = this.state

        switch(source) {
            case 'youtube': return this.renderYoutube()

            default: console.error('Video.jsx - Unknown source', source) && null
        }
    }

    renderYoutube() {
        const { embedUrl } = this.state

        return (
            <iframe className={css.youtube} src={embedUrl} />
        )
    }

    render() {
        const { embedUrl } = this.state

        if(! embedUrl) return null;

        return (
            <div className={css.overlay} id="video-modal" tabIndex="0">
                <header className={css.header}></header>
                {this.renderEmbed()}
                <button aria-controls="video-modal" className={cn(css.close, 'flex')} onClick={this._close} type="button">
                    <div>
                        <Icon icon="close" />
                    </div>
                </button>
            </div>
        )
    }
}

export default Video
