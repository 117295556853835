import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import Modal from 'react-modal';
import useEvent from 'hooks/use-event'
import cn from 'classnames'

import './Popup.scss'

const Popup = ({ className, contentClassName, useCloseBtn, isOpened, onClose, children, modalAriaLabel, closeModalButtonLabel, }) => {

    useEvent('keydown', event => {
        if (event.key === 'Escape') {
            onClose && onClose()
        }
    })

    return (
        <Modal
            isOpen={isOpened}
            closeTimeoutMS={500}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            onRequestClose={onClose}
            className={cn("popup", {[className]: className})}
            portalClassName="popup__root"
            contentLabel={modalAriaLabel}
        >
            <div className="popup__container">
                {useCloseBtn && <div className="popup__header">
                    <button 
                        className="popup__close-btn" 
                        onClick={onClose}
                        aria-label={closeModalButtonLabel}
                    >
                        <Icon use="close" className="popup__close-btn-icon" />
                    </button>
                </div>}
                <div className={cn("popup__content", {[contentClassName]: contentClassName})}>
                    {children}
                </div>
            </div>
        </Modal>
    )
}

Popup.defaultProps = {
    isOpened: false,
    useCloseBtn: true
}

Popup.propTypes = {
    children: PropTypes.node,
    isOpened: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    useCloseBtn: PropTypes.bool,
    onClose: PropTypes.func,
    closeModalButtonLabel: PropTypes.string.isRequired,
    modalAriaLabel: PropTypes.string.isRequired,

}

Popup.propTypesMeta = 'exclude'

export default Popup
