import React from 'react'
import PropTypes from 'prop-types'
import PoorText from 'components/PoorText'
import cn from 'classnames'

import './MediaCaption.scss'

const MediaCaption = ({ textHtml, className }) => {
    return (
        <PoorText
            className={cn('media-caption', {[className]: className})}
            text={textHtml}
        />
    )
}

MediaCaption.propTypes = {
    textHtml: PropTypes.string,
    className: PropTypes.string
}

MediaCaption.propTypesMeta = 'exclude'

export default MediaCaption
