import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Filter from 'components/Filter'
import ReactSelectWrapper from 'components/ReactSelectWrapper'
import idHelper from 'js/id-helper'

function onChangeHandler(value) {
    this.onChange(this.name, value)
}

function formatValue(value) {   
    if (typeof value === 'object') {
        return {
            value: value.id,
            label: value.name
        }
    }

    return {
        value,
        label: value
    }
}

function getSelected(options, selected){   
    let newValue;

    if (typeof selected === 'array') {
        newValue = options.filter( i => selected.includes(i.value));
    }

    if (typeof selected === 'string') {
        let stringToArr = selected.split(",");
        newValue = options.filter( i => stringToArr.includes(i.value));
    }
    
    return newValue
} 

const FilterValue = ({
    clearable,
    disabled,
    labels,
    name,
    values,
    value,
    onChange,
    multiple,
    isLoading
}) => {
    const [id] = useState(idHelper.generateUniqueId('filter-value'));
    const labelId = `${id}-label`;
    
    return (
    <Filter disabled={disabled} title={labels.title} id={labelId}>
        {values && (
            <ReactSelectWrapper>
                <Select
                    backspaceToRemoveMessage=""
                    closeOnSelect={!multiple}
                    clearable={clearable}
                    isDisabled={disabled}
                    joinValues={true}
                    isMulti={multiple}
                    name={`filter_${name}`}
                    onChange={onChangeHandler.bind({ name, onChange })}
                    options={values.map(formatValue)}
                    placeholder={labels.addFilter}
                    simpleValue={true}
                    isLoading={isLoading}
                    value={getSelected(values.map(formatValue), value)}
                    noOptionsMessage={() => labels.noOptionsMessage}
                    classNamePrefix="Select"
                    aria-labelledby={labelId}
                />
            </ReactSelectWrapper>
        )}
    </Filter>
)}

FilterValue.defaultProps = {
    clearable: false,
    value: '',
    multiple: true
}

FilterValue.propTypes = {
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    labels: PropTypes.shape({
        title: PropTypes.string.isRequired,
        addFilter: PropTypes.string.isRequired,
        noOptionsMessage: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
        PropTypes.oneOf([null, undefined])
    ]).isRequired,
    multiple: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool
}

export default FilterValue
