import React from 'react'
import PropTypes from 'prop-types'
import CancelButton from 'components/CancelButton'
import Modal from 'react-modal';
import useEvent from 'hooks/use-event'
import cn from 'classnames'

import './ContentPopup.scss'

const ContentPopup = ({ className, contentClassName, closeButtonLabel = 'Lukk', isOpened, onClose, children }) => {

    useEvent('keydown', event => {
        if (event.key === 'Escape') {
            onClose && onClose()
        }
    })

    return (
        <Modal
            isOpen={isOpened}
            closeTimeoutMS={500}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            onRequestClose={onClose}
            bodyOpenClassName="content-popup__page-body-when-opened"
            className={cn("content-popup", {[className]: className})}
            portalClassName="content-popup__root"
        >
            <div className="content-popup__container">
                <div className="content-popup__wrapper">
                    <CancelButton 
                        text={closeButtonLabel}
                        onClick={onClose}
                        className="content-popup__close-btn"
                    />
                    <div className={cn("content-popup__content", {[contentClassName]: contentClassName})}>
                        {children}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

ContentPopup.defaultProps = {
    isOpened: false,
}

ContentPopup.propTypes = {
    children: PropTypes.node,
    isOpened: PropTypes.bool,
    className: PropTypes.string,
    closeButtonLabel: PropTypes.string,
    contentClassName: PropTypes.string,
    onClose: PropTypes.func
}

ContentPopup.propTypesMeta = 'exclude'

export default ContentPopup
