import fromQueryString from '@creuna/utils/from-query-string';

const _inQueryStringArrayDelimiter = '~';

const getQueryParams = () => {
    var url = window.location.toString()
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    })
    return params;
}

const _queryValueAsArray = value => {
    value = value || [];
    return Array.isArray(value) ? value : value.split(',');
};

const updatePageQueryString = query => {
    query = { ...query };

    for (var key in query) {
      var value = query[key];
      if (Array.isArray(value)) {
        query[key] = value.join(_inQueryStringArrayDelimiter);

      }
    }
    const updatedQueryString = new URLSearchParams(query).toString();

    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + updatedQueryString ? `?${updatedQueryString}` : '';
    window.history.replaceState({}, document.title, newUrl);
};

const fillQueryFromPageQueryString = query => {
    var parsedQuery = fromQueryString(window.location.search.replace(/\+/g, ' '));

    for (var key in parsedQuery) {
        var value = parsedQuery[key];
        if (value.includes(_inQueryStringArrayDelimiter))
          parsedQuery[key] = value.split(_inQueryStringArrayDelimiter);
    }
    return Object.assign(query, parsedQuery);
};

const updateFilterFromQuery = (query, fieldName, filterItems) => {
  var queryValues = _queryValueAsArray(query[fieldName]);
  filterItems.forEach(item => {
    item.selected = queryValues.includes(item.value);
  });
};

export default {
    updatePageQueryString,
    updateFilterFromQuery,
    fillQueryFromPageQueryString,
    getQueryParams
};
