import React from 'react'
import LinkWithIcon from 'components/LinkWithIcon'
import MarketInsightLinksBlockListColumnItem from './MarketInsightLinksBlockListColumnItem.jsx'
import PropTypes from 'prop-types';

const MarketInsightLinksBlockListColumn = ({ heading, items }) => (
    <div 
        className='market-insight-links-block__column' 
    >
        {heading && 
            <h3 className='market-insight-links-block__column-heading'>{heading}</h3>
        }
        {items && items.length > 0 ? (
            <ul className='market-insight-links-block__column-list'>
                {items.map((item, index) => (
                    <li className='market-insight-links-block__column-item' key={index}>
                        <LinkWithIcon
                            {...item.link}
                            hideIcon={!item.isRestricted}
                            type={item.isRestricted ? LinkWithIcon.types.restricted : LinkWithIcon.types.regular }
                            className='market-insight-links-block__column-link'
                        />
                    </li>
                ))}
            </ul>
        ) : null}
    </div>
);

MarketInsightLinksBlockListColumn.propTypes = {
    heading: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact(MarketInsightLinksBlockListColumnItem.propTypes))
}

export default MarketInsightLinksBlockListColumn;