import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './PublishedDate.scss'

const PublishedDate = ({ label, date, dateFormatted, className }) => 
    <div className={cn("published-date", className)}>
        {label}:<time className="published-date__time" dateTime={date}>{dateFormatted}</time>
    </div>

PublishedDate.propTypes = {
    label: PropTypes.string,
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    className: PropTypes.string
}

export default PublishedDate
