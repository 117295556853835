import React from "react";
import PropTypes from "prop-types";

const Anchor = ({ id, text }) =>
    id ? (
        <a id={id} data-text={text}></a>
    ) : null;

Anchor.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
};

export default Anchor;
