import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Categories.scss'

const Categories = ({
    categories,
    labels = {
        all: 'Alle',
        categoriesNavigationAriaLabel: 'Filter med Kategorier'
    },
    onClick,
    selectedCategory
}) => (
    <nav className="tags" aria-label={labels.categoriesNavigationAriaLabel}>
        <button
            className={cn('tag', {
                ['active']: !selectedCategory
            })}
            onClick={onClick}
            role="button"
        >{labels.all}</button>
        {categories.map(c => (
            <button
                className={cn('tag', {
                    ['active']: selectedCategory && selectedCategory.id === c.id
                })}
                data-category={c.id}
                key={c.id}
                onClick={onClick}
                role="button"
            >{c.name}</button>
        ))}
    </nav>
)

Categories.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    labels: PropTypes.shape({
        all: PropTypes.string.isRequired,
        categoriesNavigationAriaLabel: PropTypes.string.isRequired
    }),
    onClick: PropTypes.func.isRequired,
    selectedCategory: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }),
}

export default Categories
