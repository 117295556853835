import React from 'react'
import DateBox from 'components/DateBox'
import MarketInsightCalendarEvent from './MarketInsightCalendarEvent.jsx'
import PropTypes from 'prop-types'
import { DatePeriod} from '../../constants'
import './MarketInsightCalendarDate.scss'
import cn from 'classnames'
import { DateBoxViews } from 'components/DateBox/DateBoxViews';

const MarketInsightCalendarDate = ({ className, date, dateFormatted, datePeriod, events, view }) => {
    return (
        <div className={cn('market-insight-calendar-date', className)}>
            <div className='market-insight-calendar-date__box'>
                <DateBox dateFormatted={dateFormatted} date={date} datePeriod={datePeriod} view={view} />
            </div>
            <div className='market-insight-calendar-date__event-list'>
                {events.map((event, index) => (
                    <MarketInsightCalendarEvent {...event} key={index} datePeriod={datePeriod} />
                ))}
            </div>
        </div>
    )
};

MarketInsightCalendarDate.propTypes = {
    className: PropTypes.string,
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    datePeriod: PropTypes.oneOf(Object.values(DatePeriod)),
    events: PropTypes.arrayOf(PropTypes.exact(MarketInsightCalendarEvent.propTypes)).isRequired,
    view: PropTypes.oneOf(Object.values(DateBoxViews))
}

export default MarketInsightCalendarDate;