import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import jquery from 'jquery'

import Search from 'hoc/Search'

import Icon from 'components/Icon'
import Loader from 'components/Loader'
import SearchResults from 'components/SearchResults'

import { toggleScrollLock } from '../../utils'

import css from './SearchOverlay.scss'

class SearchOverlay extends React.Component {
    static propTypes = {
        labels: PropTypes.shape({
            close: PropTypes.string.isRequired,
            placeholder: PropTypes.string.isRequired,
        }),
        employees: PropTypes.shape({
            results: PropTypes.array.isRequired,
            totalCount: PropTypes.number.isRequired,
            moreLink: PropTypes.string
        }).isRequired,
        pages: PropTypes.shape({
            results: PropTypes.array.isRequired,
            totalCount: PropTypes.number.isRequired,
            moreLink: PropTypes.string
        }).isRequired,
        language: PropTypes.string.isRequired,
        loadMore: PropTypes.func.isRequired,
        query: PropTypes.string,
        search: PropTypes.func.isRequired,
        searching: PropTypes.bool.isRequired,
        submittedSearchQuery: PropTypes.string.isRequired,
        error: PropTypes.string,
        searchErrorLabel: PropTypes.string.isRequired,
    }

    state = {
        visible: false
    }

    constructor() {
        super()

        this._debouncedSearch = debounce(this._search, 500)
        this._throttledSearch = throttle(this._search, 500)
    }

    componentDidMount() {
        window.addEventListener('keydown', this._onKeydown)

        Array.from(document.getElementsByClassName('search-button')).forEach(toggle => {
            toggle.addEventListener('click', this._toggle)
        })
    }

    _search = query => this.props.search(query)

    _loadMore = e => this.props.loadMore(e.target.dataset.type)

    _handleOnChange = e => {
        this.q = e.target.value
        this._debouncedSearch(e.target.value)
    }

    _handleFormOnClick = e => {
        // Only close the overlay if you clicked the form background
        if(e.target === e.currentTarget) {
            this._close()
        }
    }

    _handleFormOnSubmit = e => {
        e.preventDefault()
        this._throttledSearch(this.q ? this.q : this.props.query)
    }

    _onKeydown = e => e.keyCode === 27 && this._close(e)

    _setFocus = () => {
        if(! this.state.hidden && this.input && this.input.focus) {
            this.input.focus()
        }
    }

    _toggle = e => {
        if(e && e.preventDefault) {
            e.preventDefault()
        }

        if(this.overlay.style.display === 'block') {
            this._close()
            e.currentTarget.setAttribute('aria-expanded', false)
        } else {
            this._open()
            e.currentTarget.setAttribute('aria-expanded', true)
        }
    }

    _open = () => {
        const overlay = jquery(this.overlay)

        toggleScrollLock()
        overlay.show(0, () => {
            overlay.animate({ opacity: 1 })
            this.input.focus()
        })
    }

    _close = () => {
        const overlay = jquery(this.overlay)
        const openSearchButton = jquery(".search-button")

        // Prevent multiple "close" actions when ESC'ing
        if(! this.overlay.style.display) return

        overlay.animate({ opacity: 0 }, () => {
            overlay.removeAttr('style')
        })
        openSearchButton.attr('aria-expanded', false)
        toggleScrollLock()
    }

    render() {
        const {
            employees,
            labels,
            pages,
            searching,
            query,
            submittedSearchQuery,
            error,
            searchErrorLabel,
        } = this.props

        return (
            <form
                className={css.overlay}
                onClick={this._handleFormOnClick}
                onSubmit={this._handleFormOnSubmit}
                ref={node => this.overlay = node}
                id="search-form"
            >
                <header className={css.header}>
                    <div className={css.wrapper}>
                        <div className={css.input}>
                            {searching
                                ? <Loader />
                                : <button type="submit" className={css.searchBtn}><Icon icon="search" size={25} /></button>
                            }
                            <label
                                className={css.label}
                                htmlFor="search-overlay"
                            >{labels.placeholder}</label>
                            <input
                                autoComplete="off"
                                className={css.input}
                                defaultValue={query}
                                id="search-overlay"
                                name="q"
                                onChange={this._handleOnChange}
                                placeholder={labels.placeholder}
                                ref={node => this.input = node}
                                type="search"
                            />
                        </div>
                        <button
                            aria-controls="search-overlay-container"
                            className={cn('typo-small', 'flex', css.close)}
                            onClick={this._toggle}
                            type="button"
                        >
                            <div>
                                <span>{labels.close}</span>
                                <Icon icon="close" />
                            </div>
                        </button>
                    </div>
                </header>
                <SearchResults
                    className={css.results}
                    employees={employees}
                    labels={labels}
                    loadMore={this._loadMore}
                    pages={pages}
                    searching={searching}
                    submittedSearchQuery={submittedSearchQuery}
                />
                {error && (
                    <div
                        className={css.error}
                        dangerouslySetInnerHTML={{ __html: searchErrorLabel }}
                    />
                )}
            </form>
        )
    }
}

export default Search(SearchOverlay)
