import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import './MoreFeatureArticles.scss'
import cn from 'classnames'

const fallbackImageSrc = '/Seafood/Static/img/image-placeholder-light.svg';

const MoreFeatureArticlesItem = ({ text, image, url }) => {
    const imageSrc =  image && image.src ? image.src : fallbackImageSrc
    return (
        <a href={url} className="more-feature-articles__item">
            <div className={cn("more-feature-articles__item-image-container", {
                "more-feature-articles__item-image-container_no-image": !image || !image.src
            })}>
                <ResponsiveImage 
                    {...image} 
                    src={imageSrc}
                    fluid={true}
                    className="more-feature-articles__item-image responsive-image_size_more-feature-articles" 
                />
            </div>
            
            {text && (
                <div className="more-feature-articles__item-text">{text}</div>
            )}
        </a>
    )
}

MoreFeatureArticlesItem.propTypes = {
    image: PropTypes.exact(ResponsiveImage.propTypes),
    text: PropTypes.string,
    url: PropTypes.string
}

export default MoreFeatureArticlesItem
