import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import Lightbox from 'components/Lightbox'
import Photographer from 'components/Photographer'
import './ImageWithPopup.scss'
import cn from 'classnames'

const ImageWithPopup = ({ image, photographer, className }) => {
    return (
        <div className={cn('image-with-popup', className)}>
            <Lightbox>
                <div className="image-with-popup__holder">
                    <ResponsiveImage
                        className="responsive-image_size_image-with-popup"
                        {...image}
                        fluid={true}
                        transform="fill"
                    />
                </div>
            </Lightbox>
            {photographer && (
                <Photographer
                    className="photographer_in-image-with-popup"
                    {...photographer}
                />
            )}
        </div>
    )
}

ImageWithPopup.propTypes = {
    image: PropTypes.exact(ResponsiveImage.propTypes),
    photographer: PropTypes.exact(Photographer.propTypes),
    className: PropTypes.string
}

export default ImageWithPopup
