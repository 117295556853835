import React from 'react'
import PropTypes from 'prop-types'
import './Pager.scss'
import { format } from 'utils'
import ReactSelect from 'react-select'
import Icon from 'components/Icon'
import ReactSelectWrapper from 'components/ReactSelectWrapper'
import cn from 'classnames'

const Pager = ({
    page,
    totalPages,
    nextLabel = 'Next',
    prevLabel = 'Prev',
    pageLabel = '{0} of {1} pages',
    selectPageLabel = 'Select page',
    className,
    onPageChanging = () => {}
}) => {

    const [pages, setPages] = React.useState([]);

    const [showPrev, setShowPrev] = React.useState(false);
    const [showNext, setShowNext] = React.useState(false);

    const onPageChange = e => {
        onPageChanging({activePageNumber: e.value})
    }

    const onPrevButtonClick = e => {
        e.preventDefault()
        onPageChanging({activePageNumber: page - 1})
    }

    const onNextButtonClick = e => {
        e.preventDefault()
        onPageChanging({activePageNumber: page + 1})
    }

    const selectedPage = {
        value: page,
        label: format(pageLabel, [page, totalPages])
    }

    React.useEffect(() => {
        setShowPrev(page > 1);
        setShowNext(page < totalPages);
    }, [page, totalPages])

    React.useEffect(() => {
        let newPages = [];
        for (let i = 1; i <= totalPages; i++) {
            newPages.push(i)
        }
        setPages(newPages);
    }, [totalPages])

    return (
        <div className={cn('pager', className)}>
            {showPrev && (
                <button
                    onClick={onPrevButtonClick}
                    className="pager__button pager__button_prev"
                >
                    <Icon
                        use="arrow-left-white"
                        className="pager__button-icon"
                    />
                    <span className=" pager__button-text">{prevLabel}</span>
                </button>
            )}
            <ReactSelectWrapper>
                <ReactSelect
                    backspaceToRemoveMessage=""
                    name="pager_select"
                    className="pager__select-container"
                    classNamePrefix="pager"
                    id="pager_select_id"
                    onChange={onPageChange}
                    options={pages.map(x => ({
                        value: x,
                        label: x
                    }))}
                    value={selectedPage}
                    aria-label={selectPageLabel}
                />
            </ReactSelectWrapper>
            {showNext && (
                <button
                    onClick={onNextButtonClick}
                    className="pager__button pager__button_next"
                >
                    <span className=" pager__button-text">{nextLabel}</span>
                    <Icon
                        use="arrow-right-white"
                        className="pager__button-icon"
                    />
                </button>
            )}
        </div>
    )
}

Pager.propTypes = {
    page: PropTypes.number,
    totalPages: PropTypes.number.isRequired,
    nextLabel: PropTypes.string.isRequired,
    prevLabel: PropTypes.string.isRequired,
    pageLabel: PropTypes.string.isRequired,
    selectPageLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    onPageChanging: PropTypes.func
}

export default Pager
