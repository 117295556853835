import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ResponsiveImage from 'components/ResponsiveImage'
import BackgroundVideo from 'components/BackgroundVideo'
import Icon from 'components/Icon'
import imageResizer from 'components/ResponsiveImage/ImageResizer.js';
import Link from '../Link'

import css from './Banner.scss'

const Banner = ({ tagline, intro, image, backgroundVideo, button, lang }) => {
    const bannerContainerRef = useRef();
    const [imageWidth, setImageWidth] = useState(0);
    const [videoPaused, setVideoPaused] = useState(false);

    useEffect(() => {
        setImageWidth(imageResizer.calcImageWidth(Math.min(1440, window.screen.width), 100));
    });

    const toggleBackgroundVideoState = () => {
        setVideoPaused(prev => !prev)
    }

    return (
        <div 
            className={cn(css.banner, {
                [css.bannerWithText]: tagline || button || intro,
                [css.bannerWithVideoWithoutLink]: backgroundVideo && !button
            })} 
            ref={bannerContainerRef}
        >
            {backgroundVideo ? 
                <BackgroundVideo 
                    {...backgroundVideo}
                    pause={videoPaused}
                /> :
                    image ? 
                    <ResponsiveImage 
                        className={css.bannerImage}
                        {...image} 
                        fluid={true}
                        width={imageWidth}
                        transform="fill"
                    /> :
                    null
            }
            {(tagline || button || intro) && <div className={css.bannerContainer}>
                {tagline && <h1 className={css.tagline}>{tagline}</h1>}
                {intro && (
                    <div
                        className={cn(css.intro, 'wysiwyg')}
                        dangerouslySetInnerHTML={{ __html: intro }}
                    />
                )}
                {button && 
                    <Link 
                        {...button} 
                        className={cn(css.bannerContainerBtn, 'button compact negative-light')}
                    />
                }
            </div>}
            {backgroundVideo && (
                <React.Fragment>
                    {lang.playVideoAriaDescriptionLabel && lang.pauseVideoAriaDescriptionLabel && (
                        <div id='video-description' className={css.hidden}>
                            {videoPaused ? 
                                lang.playVideoAriaDescriptionLabel : 
                                lang.pauseVideoAriaDescriptionLabel
                            }
                        </div>
                    )}
                    <button 
                        className={css.bannerPlayBtn}
                        {...(lang.playVideoAriaLabel && lang.pauseVideoAriaLabel && {
                            'aria-label': videoPaused ? lang.playVideoAriaLabel : lang.pauseVideoAriaLabel
                        })}
                        {...(lang.playVideoAriaDescriptionLabel && lang.pauseVideoAriaDescriptionLabel && {
                            'aria-describedby': 'video-description'
                        })}
                        onClick={toggleBackgroundVideoState}
                    >
                        <Icon use={videoPaused ? 'play' : 'pause'} className={css.bannerPlayBtnIcon} />
                    </button>
                </React.Fragment>
            )}
        </div>
    )
}

Banner.propTypes = {
    tagline: PropTypes.string,
    intro: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    backgroundVideo: PropTypes.exact(BackgroundVideo.propTypes),
    button: PropTypes.exact(Link.propTypes),
    lang: PropTypes.shape({
        playVideoAriaLabel: PropTypes.string,
        pauseVideoAriaLabel: PropTypes.string,
        playVideoAriaDescriptionLabel: PropTypes.string,
        pauseVideoAriaDescriptionLabel: PropTypes.string,
    }),
}

export default Banner
