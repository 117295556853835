function track(data) {
    if(window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push(data)
    } else {
        return console.error('window.dataLayer.push - failed', window.dataLayer, data)
    }
}

export function search(query, queryresults) {
    track({
        query,
        queryresults,
        'event': 'internal_search'
    })
}

export function filterSearch(event, query, queryresults) {
    track({
        query,
        queryresults,
        event
    })
}

export function eventPreview(event, name, pathname) {
    track({
        event,
        name,
        pathname
    })
}