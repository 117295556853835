import React from 'react'
import PropTypes from 'prop-types'
import jquery from 'jquery'

import { search as trackSearch } from '../../tracking'

export default ComposedComponent => class extends React.Component {
    static displayName = 'SearchQuick'

    static defaultProps = {
        limit: 4
    }

    static propTypes = {
        classNames: PropTypes.shape({
            search: PropTypes.string,
            results: PropTypes.string
        }),
        limit: PropTypes.number.isRequired,
        language: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        query: PropTypes.string
    }

    state = {
        results: [],
        totalCount: 0,
        moreLink: null,
        error: null,
        searching: false,
        submittedSearchQuery: null,
    }

    componentDidMount() {
        if(this.props.query && this.props.query.length >= 3) {
            this.search(this.props.query)
        }
    }

    search = (query) => {
        const { language, url, limit } = this.props

        if(! query, query.length < 3) return

        this.setState({ submittedSearchQuery: null, searching: true })

        jquery.get(`${url}/quick`, {
            q: query,
            limit,
            language
        })
        .done(results => {
            trackSearch(query, results.totalCount)

            this.setState({
                error: null,
                searching: false,
                submittedSearchQuery: query,
                ...results
            })
        })
        .fail(error => {
            console.error(error)
            this.setState({
                results: [],
                searching: false,
                submittedSearchQuery: null,
                error: error.responseText || error.statusText,
            })
        })
    }

    render() {
        return (
            <ComposedComponent
                {...this.props}
                {...this.state}
                search={this.search}
            />
        )
    }
}
