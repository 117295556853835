

import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import css from './EmbeddedVideo.scss'
import cn from 'classnames'

const EmbeddedVideo = ({ videoUrl, playing, className }) => {
    return (
        <div className={cn(css.embeddedVideo, className)}>
            <ReactPlayer 
                playing={playing} 
                url={videoUrl} 
                controls={true} 
            />
        </div>
    );
};

EmbeddedVideo.defaultProps = {
    playing: true
}

EmbeddedVideo.propTypes = {
    videoUrl: PropTypes.string,
    playing: PropTypes.bool,
    className: PropTypes.string
};

EmbeddedVideo.propTypesMeta = {
    className: 'exclude'
}

export default EmbeddedVideo;