import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from 'components/Icon'

import css from './StepBlock.scss'

class StepBlock extends React.Component {
    static propTypes = {
        heading: PropTypes.string,
        description: PropTypes.string.isRequired,
        image: PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string
        })
    }

    state = {
        closed: true
    }

    _handleOnClick = () => this.setState({ closed: !this.state.closed })

    render() {
        const { heading, description, image } = this.props
        const { closed } = this.state

        return (
            <div className={cn(css.block, {
                [css.closed]: closed
            })}>
                {heading && (
                    <h4 className={cn({ [css.active]: !closed })} onClick={this._handleOnClick}>
                        {heading}
                        <Icon icon="caret" size={12} />
                    </h4>
                )}
                <div className={css.content}>
                    {image && image.url && <img className={css.image} src={image.url} alt={image.alt ? image.alt : ''} />}
                    <div className={cn(css.description, "wysiwyg")} dangerouslySetInnerHTML={{__html: description}} />
                </div>
            </div>
        )
    }
}

export default StepBlock
