import React from 'react'
import PropTypes from 'prop-types'
import CountryPageActivitiesCampaignsBlockItem from './CountryPageActivitiesCampaignsBlockItem'
import './CountryPageActivitiesCampaignsBlock.scss'

const CountryPageActivitiesCampaignsBlock = ({ items }) => {
    return items ? (
        <div className='country-page-activities-campaigns-block'>
            {items.map((item, index) => (
                <CountryPageActivitiesCampaignsBlockItem {...item} key={index} />
            ))}
        </div>
    ) : null
}

CountryPageActivitiesCampaignsBlock.propTypes = {
    items: PropTypes.arrayOf(PropTypes.exact(CountryPageActivitiesCampaignsBlockItem.propTypes)),
}

export default CountryPageActivitiesCampaignsBlock
