import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FluidImage from 'components/FluidImage'
import ResponsiveImage from 'components/ResponsiveImage'

import EmployeeType from 'types/EmployeeType'
import EmployeeModal from 'components/EmployeeModal'
import Link from 'components/Link'

const VIEWS = {
    EMPLOYEE: 'employee',
    MARKETGROUP: 'marketgroup',
    PRESSTOPCONTACT: 'presstopcontact'
}

import css from './EmployeeCard.scss'

class EmployeeCard extends React.PureComponent {
    static propTypes = {
        ...EmployeeType,
        view: PropTypes.oneOf([VIEWS.EMPLOYEE, VIEWS.MARKETGROUP, VIEWS.PRESSTOPCONTACT]),
        className: PropTypes.string,
        negative: PropTypes.bool.isRequired,
        customDownloadLink: PropTypes.exact(Link.propTypes),
    }

    static defaultProps = {
        negative: false,
        view: 'employee',
        isBoardMember: false,
    }

    constructor() {
        super()

        this.state = {
            activeEmployee: false
        }

        this._onClick = this._onClick.bind(this)
        this._onClose = this._onClose.bind(this)
    }

    _onClick = e => {
        e.preventDefault()

        this.setState({
            activeEmployee: true
        })
    }

    _onClose = () => this.setState({ activeEmployee: false })

    render() {
        const {
            id,
            email,
            phone,
            className,
            name,
            negative,
            image,
            title,
            url,
            view,
            isBoardMember,
            modalLabels,
            intro,
            customDownloadLink,
        } = this.props

        const { activeEmployee } = this.state

        const imageFallbackUrl = "/Seafood/Static/img/image-placeholder-white.svg"

        return (
            <div
                className={cn('employee-card', css.card, className, {
                    [css.employee]: view === VIEWS.EMPLOYEE || !view,
                    [css.marketgroup]: view === VIEWS.MARKETGROUP,
                    [css.negative]: negative,
                    [css.boardMemberHeader]: isBoardMember,
                    [css.pressTopContact]: view === VIEWS.PRESSTOPCONTACT
                })}
            >
                {view === VIEWS.PRESSTOPCONTACT ? (
                    <header className={css.header_pressTopContact}>
                        {name && <p className={css.pressTopContactName}>{name}</p>}
                        {title && <p className={css.pressTopContactTitle}>{title}</p>}
                        {intro && (
                            <div
                                className={cn(css.intro_pressTopContact, 'wysiwyg')}
                                dangerouslySetInnerHTML={{ __html: intro }}
                            />
                        )}
                        <div className={css.pressTopContactButtons}>
                            {modalLabels && modalLabels.readMore && (
                                <a
                                    className={cn('link', css.readMoreLink_pressTopContact)}
                                    onClick={this._onClick}
                                    data-id={id}
                                    href="#"
                                >
                                    {modalLabels.readMore}
                                </a>
                            )}
                            {customDownloadLink ? (
                                <Link
                                    {...customDownloadLink}
                                    className={cn('typo-small', css.downloadImageLink_pressTopContact)}
                                />
                            ) : 
                                <a
                                    className={cn('typo-small', css.downloadImageLink_pressTopContact)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={image.url}
                                >
                                    {modalLabels.downloadImage}
                                </a>
                            }
                        </div>
                    </header>
                ) : (
                    <header className="typo-small">
                        <p
                            className={cn({
                                h4: view === VIEWS.MARKETGROUP
                            })}
                        >
                            {url && view != VIEWS.MARKETGROUP && (
                                <a className="link" data-name={name} href={url}>
                                    {name}
                                </a>
                            )}
                            {!url && view != VIEWS.MARKETGROUP && name}
                            {view === VIEWS.MARKETGROUP && name}
                        </p>
                        {title && <p>{title}</p>}
                        {!isBoardMember && (
                            <ul className={css.contactList}>
                                {view === VIEWS.MARKETGROUP && phone && (
                                    <li className={css.list}>
                                        <a
                                            className={cn(css.phone, 'link')}
                                            href={`tel:${phone}`}
                                        >
                                            {phone}
                                        </a>
                                    </li>
                                )}
                                {view === VIEWS.MARKETGROUP && email && (
                                    <li className={css.list}>
                                        <a
                                            className={cn(css.email, 'link')}
                                            href={`mailto:${email}`}
                                        >
                                            {email}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        )}
                        {isBoardMember && modalLabels && modalLabels.readMore && (
                            <a
                                className={cn('link', css.readMoreLink)}
                                onClick={this._onClick}
                                data-id={id}
                                href="#"
                            >
                                {modalLabels.readMore}
                            </a>
                        )}
                    </header>
                )}

                <div className={cn('image', css.image)}>
                    {view === VIEWS.PRESSTOPCONTACT ? (
                            <ResponsiveImage fluid src={image.url ? image.url : imageFallbackUrl} focusPoint={image.focusPoint} alt={image.alt ? image.alt : ''} />
                        ):(
                        <FluidImage
                            fallback={imageFallbackUrl}
                            size={120}
                            {...image}
                        />)
                    }
                </div>

                {activeEmployee && (
                    <EmployeeModal {...this.props} onClose={this._onClose} />
                )}
            </div>
        )
    }
}
export default EmployeeCard
