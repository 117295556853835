import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BackgroundMedia from 'components/BackgroundMedia'
import Icon from 'components/Icon'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import cn from 'classnames'
import imageResizer from 'components/ResponsiveImage/ImageResizer.js'
import './FullPageQuotationBlock.scss'

const FullPageQuotationBlock = ({
    text,
    author,
    mediaClassName,
    background
}) => {
    const [quoteContent, quoteContentInView] = useInView({
        rootMargin: '-35% 0px'
    });

    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    useEffect(() => {
        setImageWidth(imageResizer.calcImageWidth(window.screen.width, 100));
        setImageHeight(imageResizer.calcImageHeight(window.screen.height, 100));
    });
    
    return (
        <div
            className='full-page-quotation-block'
        >
            {background && (background.video || background.image) && (
                    <BackgroundMedia className={cn({
                        [mediaClassName]: mediaClassName
                    })} {...background}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    />        
            )}

            <motion.div 
                ref={quoteContent} 
                animate={{ 
                    opacity: quoteContentInView ? 1 : 0, 
                    y: quoteContentInView ? 0 : '50%'
                }}
                transition={{ duration: 2 }} 
                className="full-page-quotation-block__text-container"
            >
                <Icon className="full-page-quotation-block__icon" use="quote" />
                {text && (
                    <blockquote className="full-page-quotation-block__text">
                        <span className="full-page-quotation-block__text-wrapper">{text}</span>
                    </blockquote>
                )}
                {author && (
                    <p className="full-page-quotation-block__author">
                        <span className="full-page-quotation-block__author-wrapper">
                        {'– '}{author}
                        </span>
                    </p>
                )}
            </motion.div>
        </div>
    )
}

FullPageQuotationBlock.propTypes = {
    text: PropTypes.string,
    author: PropTypes.string,
    background: PropTypes.exact(BackgroundMedia.propTypes),
    mediaClassName: PropTypes.string
}

export default FullPageQuotationBlock
