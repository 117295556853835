import React from 'react'
import PropTypes from 'prop-types'

import FilterList from 'hoc/FilterList'
import EmployeeCard from 'components/EmployeeCard'

import EmployeeType from 'types/EmployeeType'

import css from './MarketGroup.scss'

class MarketGroup extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(EmployeeType)).isRequired,
        labels: PropTypes.shape({
            all: PropTypes.string.isRequired,
            person: PropTypes.string.isRequired,
            people: PropTypes.string.isRequired,
            resultsTableAriaLabel: PropTypes.string.isRequired
        }).isRequired,
        totalItems: PropTypes.number.isRequired,
        selectedCategory: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })
    }

    static defaultProps = {
        labels: {
            resultsTableAriaLabel: "Tabell med resultater"
        },
    }

    render() {
        const { items, labels, totalItems, selectedCategory } = this.props

        const headerPrefix = selectedCategory && `${selectedCategory.name} - `
        const headerLabel = totalItems === 1 ? labels.person : labels.people

        return (
            <div className={css.wrapper} aria-atomic='true' aria-live='polite' aria-label={labels.resultsTableAriaLabel}>
                <h2 className={css.title}>
                    {headerPrefix}
                    {`${totalItems} ${headerLabel}`}
                </h2>
                <div className={css.people}>
                    {items && items.map(i => (
                        <EmployeeCard
                            className={css.employeeCard}
                            key={i.id}
                            view="marketgroup"
                            {...i}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default FilterList(MarketGroup)
