import React from 'react'
import PropTypes from 'prop-types'

import Input from 'components/Input'
import Modal, { modalType } from 'components/Modal'
import Loader from 'components/Loader'
import StepIndicator from 'components/StepIndicator'

import { format } from 'utils'

import css from './OrderListModal.scss'

import completeImage from 'assets/orderlist/shipping.svg'
import emptyImage from 'assets/orderlist/empty.svg'


class OrderListModal extends React.Component {
    static propTypes = {
        labels: PropTypes.shape({
            orderListEmptyTitle: PropTypes.string.isRequired,
            orderListEmptyText: PropTypes.string.isRequired,
            orderList: PropTypes.string.isRequired,
            orderListText: PropTypes.string.isRequired,
            ok: PropTypes.string.isRequired,
            material: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired,
            remove: PropTypes.string.isRequired,
            cancelOrder: PropTypes.string.isRequired,
            orderAddress: PropTypes.string.isRequired,
            orderAddressText: PropTypes.string.isRequired,
        }).isRequired,
        user: PropTypes.shape({
            companyName: PropTypes.string,
            address: PropTypes.string,
            postNumber: PropTypes.string,
            city: PropTypes.string,
            name: PropTypes.string,
            phoneNumber: PropTypes.string,
            email: PropTypes.string,
        }),
        onAmountChange: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onComplete: PropTypes.func.isRequired,
        onDone: PropTypes.func.isRequired,
        orders: PropTypes.object.isRequired,
        orderSubmitUrl: PropTypes.string,
        orderComplete: PropTypes.bool.isRequired,
        orderError: PropTypes.bool.isRequired, // TODO: oneOfType bool/string ?
        isOrdering: PropTypes.bool.isRequired,
        onUserChange: PropTypes.func.isRequired,
        orderErrorMessage: PropTypes.string.isRequired,
    }

    state = {
        step: 1,
        validation: {},
    }

    _handleProceed = () => {
        if(this._stepValidation())
            this.setState({ step: this.state.step + 1 })
    }

    _handleComplete = () => {
        if(this._stepValidation())
            this.props.onComplete()
    }

    _handlePrevious = () => this.setState({ step: this.state.step - 1 })

    _close = () => {
        if(this.modal && this.modal._close) {
            this.modal._close()
        } else {
            this.onClose()
        }
    }

    _handleCancel = () => {
        this._close()
        window.setTimeout(this.props.onCancel, this.modal.timeout)
    }

    _handleDone = () => {
        this._close()
        window.setTimeout(this.props.onDone, this.modal.timeout)
    }

    _stepValidation = () => {
        const { state: { step }, props: { user } } = this

        let validation = {}

        switch(step) {
            case 2:
                if(! user.companyName) validation['companyName'] = true
                if(! user.address) validation['address'] = true
                if(! user.postNumber) validation['postNumber'] = true
                if(! user.city) validation['city'] = true
                break;

            case 3:
                if(! user.name) validation['name'] = true
                if(! user.phoneNumber) validation['phoneNumber'] = true
                if(! user.email) validation['email'] = true
                break;

            default:
                return true
        }

        this.setState({
            validation,
        })

        if(Object.keys(validation).length === 0) return true

        return false
    }

    _handleBlur = e => {
        e.persist()

        const { validation } = this.state
        const { name, value } = e.currentTarget

        if(validation && validation[name]) {
            if(value) {
                const newValidation = { ...validation }
                delete newValidation[name]
                this.setState({ validation: newValidation })
            }
        }
    }

    render() {
        const {
            labels,
            orderComplete,
            onAmountChange,
            onClose,
            onRemove,
            onUserChange,
            orders,
            user,
            isOrdering,
            orderError,
            orderErrorMessage,
        } = this.props

        const {
            step,
            validation,
        } = this.state

        const empty = Object.keys(orders).length === 0

        const hasImage = empty

        return (
            <Modal
                hasImage={hasImage}
                labels={labels}
                type={modalType.mini}
                onClose={onClose}
                ref={modal => this.modal = modal}
            >
                {orderComplete && [
                    <div key="image" className={css.image} style={{ backgroundImage: `url(${completeImage})` }}>
                        <img src={completeImage} alt={labels.orderCompleteTitle ? labels.orderCompleteTitle : ''} />
                    </div>,
                    <div className={css.content} key="content">
                        <header>
                            <h2>{labels.orderCompleteTitle}</h2>
                            <p>{format(labels.orderCompleteText, user.email)}</p>
                        </header>
                        <hr />
                        <button
                            className="button small"
                            onClick={this._handleDone}
                        >{labels.done}</button>
                    </div>
                ]}
                {empty && !orderComplete && [
                    <div key="image" className={css.image} style={{ backgroundImage: `url(${emptyImage})` }}>
                        <img src={emptyImage} alt={labels.orderListEmptyTitle ? labels.orderListEmptyTitle : ''} />
                    </div>,
                    <div className={css.content} key="content">
                        <header>
                            <h2>{labels.orderListEmptyTitle}</h2>
                            <p>{labels.orderListEmptyText}</p>
                        </header>
                        <hr />
                        <button
                            className="button small"
                            onClick={this._close}
                        >{labels.ok}</button>
                    </div>
                ]}
                {!empty && !orderComplete && (
                    <div className={css.content}>
                        <StepIndicator
                            className={css.steps}
                            step={step}
                            steps={3}
                        />
                        {step === 1 && (
                            <div>
                                <header>
                                    <h2 className="h2">{labels.orderList}</h2>
                                    <p>{labels.orderListText}</p>
                                </header>
                                <table className={css.orders}>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">{labels.material}</th>
                                            <th>{labels.amount}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(orders).map(id => (
                                            <tr key={id}>
                                                <td>
                                                    <img src={orders[id].image} alt={orders[id].name ? orders[id].name : ''} />
                                                </td>
                                                <td>
                                                    <h3 className="h4 wysiwyg">
                                                        {orders[id].name}
                                                    </h3>
                                                    <small>{orders[id].tags.join(', ')}</small>
                                                </td>
                                                <td className={css.amount}>
                                                    <input
                                                        className="input"
                                                        onChange={e => onAmountChange(id, e.target.value)}
                                                        type="number"
                                                        value={orders[id].amount}
                                                    />
                                                    <span className="wysiwyg">
                                                        <a onClick={() => onRemove(id)}>{labels.remove}</a>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className={css.actions}>
                                    <button className="button secondary small" onClick={this._close}>{labels.addMore}</button>
                                    <button className="button small" onClick={this._handleProceed}>{labels.proceed}</button>
                                    <a onClick={this._handleCancel}>{labels.cancelOrder}</a>
                                </div>
                            </div>
                        )}
                        {step === 2 && (
                            <div>
                                <header>
                                    <h2 className="h2">{labels.contactPerson}</h2>
                                    <p>{labels.orderAddressText}</p>
                                </header>
                                <hr />
                                <form className="form-wrapper">
                                    <Input
                                        className="input-three-quarter-width"
                                        name="companyName"
                                        label={labels.companyName}
                                        value={user.companyName}
                                        onBlur={this._handleBlur}
                                        onChange={onUserChange}
                                        validation={validation.companyName && labels.validationRequired}
                                    />
                                    <Input
                                        className="input-half-width"
                                        name="address"
                                        label={labels.address}
                                        value={user.address}
                                        onBlur={this._handleBlur}
                                        onChange={onUserChange}
                                        validation={validation.address && labels.validationRequired}
                                    />
                                    <Input
                                        className="input-one-quarter-width"
                                        name="postNumber"
                                        type="number"
                                        label={labels.postNumber}
                                        value={user.postNumber}
                                        onBlur={this._handleBlur}
                                        onChange={onUserChange}
                                        validation={validation.postNumber && labels.validationRequired}
                                    />
                                    <Input
                                        className="input-one-quarter-width"
                                        name="city"
                                        label={labels.city}
                                        value={user.city}
                                        onBlur={this._handleBlur}
                                        onChange={onUserChange}
                                        validation={validation.city && labels.validationRequired}
                                    />
                                </form>
                                <hr />
                                <div className={css.actions}>
                                    <button className="button secondary small" onClick={this._handlePrevious}>{labels.previous}</button>
                                    <button className="button small" onClick={this._handleProceed}>{labels.proceed}</button>
                                    <a onClick={this._handleCancel}>{labels.cancelOrder}</a>
                                </div>
                            </div>
                        )}
                        {step === 3 && (
                            <div>
                                <header>
                                    <h2 className="h2">{labels.orderAddress}</h2>
                                    <p>{labels.orderAddressText}</p>
                                </header>
                                <hr />
                                <form className="form-wrapper">
                                    <Input
                                        className="input-three-quarter-width"
                                        name="name"
                                        label={labels.contactPerson}
                                        value={user.name}
                                        onBlur={this._handleBlur}
                                        onChange={onUserChange}
                                        validation={validation.name && labels.validationRequired}
                                    />
                                    <Input
                                        className="input-half-width"
                                        name="phoneNumber"
                                        label={labels.phoneNumber}
                                        value={user.phoneNumber}
                                        onBlur={this._handleBlur}
                                        onChange={onUserChange}
                                        validation={validation.phoneNumber && labels.validationRequired}
                                    />
                                    <Input
                                        className="input-half-width"
                                        name="email"
                                        label={labels.email}
                                        value={user.email}
                                        type="email"
                                        onBlur={this._handleBlur}
                                        onChange={onUserChange}
                                        validation={validation.email && labels.validationRequired}
                                    />
                                </form>
                                <hr />
                                {orderError && (
                                    <div
                                        className={css.error}
                                        dangerouslySetInnerHTML={{ __html: orderErrorMessage }}
                                    />
                                )}
                                {isOrdering && <Loader center />}
                                {!isOrdering && (
                                    <div className={css.actions}>
                                        <button
                                            className="button secondary small"
                                            onClick={this._handlePrevious}
                                        >{labels.previous}</button>
                                        <button
                                            className="button small"
                                            onClick={this._handleComplete}
                                        >{labels.complete}</button>
                                        <a onClick={this._handleCancel}>{labels.cancelOrder}</a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        )
    }
}

export default OrderListModal
