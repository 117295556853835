import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './DownloadLink.scss'
import Icon from 'components/Icon'

const DownloadLink = ({
    className,
    url,
    text,
    ariaLabel,
    iconName
}) => {
    return (
        <a 
            aria-label={ariaLabel} 
            href={url}
            className={cn('download-link', className)}
            download 
        >
            {text && <span className='download-link__text'>{text}</span>}
            {iconName && (
                <Icon 
                    use={iconName}
                    className="download-link__icon" 
                />
            )}
        </a>
    )
}

DownloadLink.propTypes = {
    url: PropTypes.string,
    ariaLabel: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    iconName: PropTypes.string
}

DownloadLink.propTypesMeta = 'exclude'

export default DownloadLink
