import React from 'react'
import PropTypes from 'prop-types'

const MarketInsightCtaHeading = ({ heading }) => {
    return (
        heading ? <h3 className='market-insight-cta-link-list__heading'>{heading}</h3> : null
    )
};

MarketInsightCtaHeading.propTypes = {
    heading: PropTypes.string,
    componentName: PropTypes.string
}

export default MarketInsightCtaHeading;