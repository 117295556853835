import React from 'react'
import PropTypes from 'prop-types'
import './MoreFeatureArticles.scss'
import MoreFeatureArticlesItem from './MoreFeatureArticlesItem'

const MoreFeatureArticles = ({ heading, articles }) => {
    return articles ? (
        <div className="more-feature-articles">
            <div className="more-feature-articles__container">
                {heading && (
                    <h2 className="more-feature-articles__heading">{heading}</h2>
                )}
                <div className="more-feature-articles__items">
                    {articles.map((article, index) => (
                        <MoreFeatureArticlesItem key={index} {...article} />
                    ))}
                </div>
            </div>
        </div>
    ) : null
}

MoreFeatureArticles.propTypes = {
    heading: PropTypes.string,
    articles: PropTypes.arrayOf(
        PropTypes.exact(MoreFeatureArticlesItem.propTypes)
    )
}

export default MoreFeatureArticles
