import React from 'react'
import PropTypes from 'prop-types'
import TypedLink from 'components/Models/TypedLink/TypedLink'
import LinkWithIcon from 'components/LinkWithIcon'
import './CountryPageLinksBlock.scss'

const CountryPageLink = ({ link, description }) => {
    return (
        <React.Fragment>
            {link && <LinkWithIcon 
                className="country-page-links-block__link"
                {...link}
            />}
            {description &&<div className="country-page-links-block__link-description">{description}</div>}
        </React.Fragment>
    )
}

CountryPageLink.propTypes = {
    link: PropTypes.exact(TypedLink.propTypes),
    description: PropTypes.string
}

export default CountryPageLink
