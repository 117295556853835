import React from 'react'
import { render } from 'react-dom'

import SearchPage from 'containers/SearchPage'

document.addEventListener("DOMContentLoaded", () => {
    const body = document.body
    const searchPageNode = document.getElementById('search-page')

    if(searchPageNode) {
        const div = document.createElement('div')
        searchPageNode.parentNode.insertBefore(div, searchPageNode)

        render(
            <SearchPage
                {...window.__SEARCHPAGE}
                language={body.dataset.lang}
            />,
            div
        )
    }
})
