import React from 'react'
import Link from 'components/Link'
import LinkWithIcon from 'components/LinkWithIcon'
import PoorText from 'components/PoorText'
import PropTypes from 'prop-types'
import MarketInsightDownloadableStatisticsBlockItem from './MarketInsightDownloadableStatisticsBlockItem'
import { MARKET_INSIGHT_STATISTICS_BLOCK_IDS } from '../../constants'

import './MarketInsightDownloadableStatisticsBlock.scss'

const MarketInsightDownloadableStatisticsBlock = ({
    heading,
    introHtml,
    statisticsList,
    statisticsArchiveLink,
    lang
}) => {
    return (
        <div className='market-insight-downloadable-statistics-block' id={MARKET_INSIGHT_STATISTICS_BLOCK_IDS.downloadableStatistics}>
            <div className='market-insight-downloadable-statistics-block__container'>
                {heading && <h2 className='market-insight-downloadable-statistics-block__heading'>{heading}</h2>}
                {introHtml && <PoorText text={introHtml} className='poor-text_in-market-insight-downloadable-statistics-block' />}
                {statisticsList && statisticsList.length > 0 ? (
                    <div className='market-insight-downloadable-statistics-block__columns'>
                        {statisticsList.map((item, index) => (
                            <MarketInsightDownloadableStatisticsBlockItem 
                                key={index}
                                {...item}
                                className='market-insight-downloadable-statistics-block__column'
                                pdfLabel={lang.pdfLabel}
                                excelLabel={lang.excelLabel}
                            />
                        ))}
                    </div>
                ) : null}
                {statisticsArchiveLink && (
                    <div className='market-insight-downloadable-statistics-block__link-holder'>
                        <LinkWithIcon
                            {...statisticsArchiveLink}
                            className='market-insight-downloadable-statistics-block__link'
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

MarketInsightDownloadableStatisticsBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    statisticsList: PropTypes.arrayOf(PropTypes.exact(MarketInsightDownloadableStatisticsBlockItem.propTypes)),
    statisticsArchiveLink: PropTypes.exact(Link.propTypes),
    lang: PropTypes.shape({
        pdfLabel: PropTypes.string.isRequired,
        excelLabel: PropTypes.string.isRequired,
    }).isRequired,
}

export default MarketInsightDownloadableStatisticsBlock