import React from 'react'
import ResponsiveImage from 'components/ResponsiveImage'
import Link from 'components/Link'
import PropTypes from 'prop-types'

const MarketInsightContactPersonsBlockContact = ({
    image,
    name,
    position,
    url
}) => {
    return (
        <div className='market-insight-contact-persons-block-contact'>
            <div className='market-insight-contact-persons-block-contact__image'>
                <ResponsiveImage 
                    {...image} 
                    className="responsive-image_size_market-insight-contact-persons-block" 
                />
            </div>
            <div className='market-insight-contact-persons-block-contact__info'>
                <Link url={url} text={name} className='market-insight-contact-persons-block-contact__link' />
                {position && <span className='market-insight-contact-persons-block-contact__position'>{position}</span>}
            </div>
        </div>
    )
}

MarketInsightContactPersonsBlockContact.propTypes = {
    url: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    name: PropTypes.string,
    position: PropTypes.string,
}

export default MarketInsightContactPersonsBlockContact