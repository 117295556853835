import PropTypes from 'prop-types'

const FilterItemType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
})

const FilterType = {
    categories: PropTypes.arrayOf(FilterItemType),
    types: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    dateRange: PropTypes.bool,
    labels: PropTypes.shape({
        addFilter: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        noOptionsMessage: PropTypes.string,
    }).isRequired
}

export default FilterType
