const trapFocus = function(elem) {
    const tabbable = elem.find('select, input, textarea, button, a').filter(':visible');
    
    const firstTabbable = tabbable.first();
    const lastTabbable = tabbable.last();
    firstTabbable.focus();

    lastTabbable.on('keydown', function (e) {
        if ((e.which === 9 && !e.shiftKey)) {
            e.preventDefault();
            firstTabbable.focus();
        }
    });

    firstTabbable.on('keydown', function (e) {
        if ((e.which === 9 && e.shiftKey)) {
            e.preventDefault();
            lastTabbable.focus();
        }
    });
    
    elem.on('keyup', function(e){
        if (e.keyCode === 27 ) {
            elem.hide();
        }
    });
}

export { trapFocus }