import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import ReactSelectWrapper from 'components/ReactSelectWrapper'

import css from './Select.scss'

function formatValue(options, value){   
    let formattedValue = options.filter( i => value.includes(i.value));
    let newValue = formattedValue.map(x => ({ value: x.value, label: x.text}));
   
    return newValue
} 

class Select extends React.Component {
    static propTypes = {
        clearable: PropTypes.bool.isRequired,
        id: PropTypes.string,
        inputId: PropTypes.string,
        multiple: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        negative: PropTypes.bool.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            selected: PropTypes.bool,
        })).isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
            PropTypes.array,
            PropTypes.oneOf([null, undefined])
        ]),
        placeholder: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        label: PropTypes.string,
    }

    static defaultProps = {
        clearable: false,
        multiple: false,
        negative: false,
    }

    state = {
        value: null
    }

    constructor(props) {
        super(props)
        const { options, value } = props

        if(options && value) {
            this.state = { value: formatValue(options, value) }
        }
    }

    _onChange = value => { 
        this.setState({ value })
    }

    render() {
        const {
            clearable,
            id,
            inputId,
            multiple,
            name,
            negative,
            options,
            placeholder,
            title,
            description,
            label
        } = this.props

        const {
            value
        } = this.state

        return (
            <div className={css.Select}>
                {title &&<h2>{title}</h2>}
                {description && (
                    <p className={css.description}>{description}</p>
                )}
                {label && <label htmlFor={id}>{label}</label>}
                <ReactSelectWrapper>
                    <ReactSelect
                        autoBlur={multiple ? false : true}
                        backspaceToRemoveMessage=""
                        className={negative ? "negative" : ""}
                        clearable={clearable}
                        id={id}
                        inputId={inputId}
                        joinValues={true}
                        labelKey="text"
                        isMulti={multiple}
                        name={name}
                        onChange={this._onChange}
                        options={options.map(
                            x => ({
                                value: x.value,
                                label: x.text
                            })
                        )}
                        placeholder={placeholder || ""}
                        simpleValue={true}
                        value={value}
                        classNamePrefix="Select"
                        onBlurResetsInput={false}
                        onSelectResetsInput={false}
                        onCloseResetsInput={false}
                    />
                </ReactSelectWrapper>
            </div>
        )
    }
}

export default Select
