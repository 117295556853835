import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FilterTableItem from 'components/FilterTableItem'

import MarketplaceRequestType from 'types/MarketplaceRequestType'

import css from './MarketplaceRequest.scss'

const MarketplaceRequest = ({
    hide,
    labels,
    company,
    description,
    contactPerson,
    email,
    phone,
    fax,
    country,
    species,
    id
}) => (
    <FilterTableItem
        colSpan={5}
        hide={hide}
        id={id}
        name={company}
        type="request"
    >
        <h2 className={cn(css.company, 'h1')}>{company}</h2>
        <div className={css.container}>
            <div className={css.description}>{description}</div>
            <div className={css.details}>
                {species && (
                    <div>
                        <h4>{labels.species}</h4>
                        {species.map(s => (
                            <span className="tag-label" key={s.id}>{s.name}</span>
                        ))}
                    </div>
                )}
                <div>
                    <h4>{labels.contactPerson}</h4>
                    {contactPerson}
                </div>
                <div>
                    <h4>{labels.email}</h4>
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
                <div>
                    <h4>{labels.phone}</h4>
                    {phone}
                </div>
                {fax && (
                    <div>
                        <h4>{labels.fax}</h4>
                        {fax}
                    </div>
                )}
                <div>
                    <h4>{labels.country}</h4>
                    {country}
                </div>
                {email && <a className="button negative small" href={`mailto:${email}`}>{labels.getInContact}</a>}
            </div>
        </div>
    </FilterTableItem>
)

MarketplaceRequest.propTypes = {
    ...MarketplaceRequestType,
    labels: PropTypes.shape({
        species: PropTypes.string.isRequired,
        contactPerson: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        fax: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        getInContact: PropTypes.string.isRequired,
    }).isRequired,
    hide: PropTypes.bool.isRequired
}


export default MarketplaceRequest
