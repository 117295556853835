import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import Search from 'hoc/Search'

import SearchResults from 'components/SearchResults'
import SearchWrapper from 'components/SearchWrapper'

import css from './SearchPage.scss'

class SearchPage extends React.Component {
    static propTypes = {
        labels: PropTypes.shape({
            employees: PropTypes.string.isRequired,
            seeMoreResults: PropTypes.string.isRequired,
            topResults: PropTypes.string.isRequired,
            xResultsForYFormat: PropTypes.string.isRequired,
            resultsTableAriaLabel: PropTypes.string.isRequired
        }),
        employees: PropTypes.shape({
            results: PropTypes.array.isRequired,
            totalCount: PropTypes.number.isRequired,
            moreLink: PropTypes.string
        }).isRequired,
        pages: PropTypes.shape({
            results: PropTypes.array.isRequired,
            totalCount: PropTypes.number.isRequired,
            moreLink: PropTypes.string
        }).isRequired,
        language: PropTypes.string.isRequired,
        loadMore: PropTypes.func.isRequired,
        query: PropTypes.string,
        search: PropTypes.func.isRequired,
        searchAriaLabel: PropTypes.string,
        searching: PropTypes.bool.isRequired,
        submittedSearchQuery: PropTypes.string.isRequired
    }

    constructor() {
        super()

        this._debouncedSearch = debounce(this._search, 500)
        this._throttledSearch = throttle(this._search, 500)
    }

    componentDidMount() {
        if(this.props.query && this.props.query.length >= 3) {
            this._search(this.props.query)
        }
    }

    _search = query => this.props.search(query)

    _loadMore = e => this.props.loadMore(e.target.dataset.type)

    _handleOnChange = e => {
        this.q = e.target.value
        this._debouncedSearch(e.target.value)
    }

    _handleOnSubmit = e => {
        e.preventDefault()
        this._throttledSearch(this.q ? this.q : this.props.query)
    }

    render() {
        const {
            employees,
            labels,
            pages,
            searching,
            submittedSearchQuery,
            query,
            searchAriaLabel
        } = this.props

        const totalResults = employees.totalCount + pages.totalCount

        return (
            <form onSubmit={this._handleOnSubmit}>
                <header className={css.header}>
                    <SearchWrapper
                        autoComplete="off"
                        buttonLabel={labels.search}
                        className={css.searchWrapper}
                        defaultValue={query}
                        name="q"
                        negative={true}
                        onChange={this._handleOnChange}
                        onClick={this._handleOnSubmit}
                        searching={searching}
                        ariaLabel={searchAriaLabel}
                    />
                    <p className={css.resultText}>
                        {submittedSearchQuery && labels.xResultsForYFormat.format([totalResults, submittedSearchQuery])}
                    </p>
                </header>
                <SearchResults
                    employees={employees}
                    labels={labels}
                    loadMore={this._loadMore}
                    pages={pages}
                />
            </form>
        )
    }
}

export default Search(SearchPage)
