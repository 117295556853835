import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import EventCard from 'components/EventCard'

import css from './EventListBlock.scss'

class EventListBlock extends React.Component {
    static propTypes = {
        heading: PropTypes.string.isRequired,
        url: PropTypes.string,
        labels: PropTypes.shape({
            seeAll: PropTypes.string
        }).isRequired,
        events: PropTypes.array.isRequired
    }

    render() {
        const {
            url,
            heading,
            labels,
            events,
        } = this.props

        return (
            <section className="list-items negative">
                <h2 className="h4">{heading}</h2>
                <div className={cn(css.events, 'list-items-content')}>
                    {events && events.map(i =>
                        <EventCard
                            key={i.id}
                            {...i}
                        />
                    )}                </div>
                <a className="see-all shortcut" href={url}>{labels.seeAll}</a>
            </section>
        )
    }
}

export default EventListBlock
