import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterList from 'hoc/FilterList';
import FilterTable from 'components/FilterTable';
import EmployeeListItem from 'components/EmployeeListItem';
import FilterShortcuts from 'components/FilterShortcuts';
import EmployeeType from 'types/EmployeeType';

const EmployeeList = ({
  defaultSortOptions,
  items,
  labels = {
    resultsTableAriaLabel: "Tabell med resultater",
    resultsCountFormatLabel: "{0} treff",
    resultsOnFormatLabel: "på {0}"
  },
  shown,
  helpText,
  listHeading,
  shortcuts,
  searchValue,
  totalItems,
  onToggle,
  onSorting
}) => {
    const [sortConfig, setSortConfig] = useState(defaultSortOptions);
    const [sortedItems, setSortedItems] = useState(items);

    const getSortDirection = (name) => {
        return sortConfig && sortConfig.direction && sortConfig.key === name
      ? sortConfig.direction
      : null;
    };

    const headers = [
        {
            text: labels.name,
            sortKey: 'name',
            direction: getSortDirection('name'),
        },
        {
            text: labels.title,
            sortKey: 'title',
            direction: getSortDirection('title'),
        },
        {
            text: labels.department,
            sortKey: 'department',
            direction: getSortDirection('department'),
        },
    ];

    const compareValues = (key, order = 'ascending') => {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
            const comparison = a[key].localeCompare(b[key], 'nb');

            return order === 'descending' ? comparison * -1 : comparison;
        };
    };

    const onSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key !== null &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({key: key, direction: direction});
        
        if (sortConfig !== null) {
            let sortedItemsTmp = [...sortedItems];
            sortedItemsTmp.sort(compareValues(key, direction));
            setSortedItems(sortedItemsTmp)
        }
    };

    React.useEffect(() => {
        setSortedItems(items);
    }, [items])

    React.useEffect(() => {
        onSorting(sortConfig);
    }, [sortConfig])

    return (
        <FilterTable
            component={EmployeeListItem}
            headers={headers}
            items={sortedItems}
            labels={labels}
            name={(item) => item.company}
            onToggle={onToggle}
            rows={[
                (item) => item.name,
                (item) => item.title,
                (item) => item.department,
            ]}
            shown={shown}
            type="employee"
            helpText={helpText}
            shortcuts={shortcuts}
            onSort={onSort}
            sortable={true}
            searchValue={searchValue}
            caption={listHeading}
            totalItems={totalItems}
        />
    );
};

EmployeeList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(EmployeeType)).isRequired,
    labels: PropTypes.shape({
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        department: PropTypes.string.isRequired,
        resultsTableAriaLabel: PropTypes.string.isRequired,
        resultsOnFormatLabel: PropTypes.string,
        resultsCountFormatLabel: PropTypes.string
    }).isRequired,
    shown: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onToggle: PropTypes.func.isRequired,
    totalItems: PropTypes.number.isRequired,
    helpText: PropTypes.string,
    listHeading: PropTypes.string,
    shortcuts: PropTypes.exact(FilterShortcuts.propTypes),
    defaultSortOptions: PropTypes.shape({
        key: PropTypes.oneOf(['name', 'title', 'department']),
        direction: PropTypes.oneOf(['ascending', 'descending']),
    }),
    searchValue: PropTypes.string,
    onSorting: PropTypes.func 
};

export default FilterList(EmployeeList);
