import Link from 'components/Link'
import PropTypes from 'prop-types';

const MarketInsightLinksBlockListColumnItem = {};

MarketInsightLinksBlockListColumnItem.propTypes = {
    isRestricted: PropTypes.bool.isRequired,
    link: PropTypes.exact(Link.propTypes).isRequired
}

export default MarketInsightLinksBlockListColumnItem;