import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Select from 'components/Select'
import SavingProgressPopup from 'components/SavingProgressPopup'

import css from './Newsletter.scss'

class Newsletter extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        newsletters: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })),
        savingProgressPopup: PropTypes.exact(SavingProgressPopup.propTypes),
        userNewsletters: PropTypes.array,
        labels: PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            newsletter: PropTypes.string.isRequired,
            newsletterPlaceholder: PropTypes.string.isRequired,
        }).isRequired
    }

    render() {
        const {
            id,
            url,
            newsletters,
            savingProgressPopup,
            userNewsletters,
            labels
        } = this.props

        return (
            <form 
                action={url} 
                className={css.newsletter}
                method="POST" 
            >
                <Select
                    title={labels.title}
                    description={labels.description}
                    label={labels.newsletter}
                    clearable={true}
                    id={id}
                    multiple={true}
                    name="ids"
                    negative={true}
                    options={newsletters}
                    placeholder={labels.newsletterPlaceholder}
                    value={userNewsletters.map((x) => (x.value))}
                />
                <input 
                    className={cn(css.submit, 'button small')}
                    type="submit"
                    value={labels.submit}
                />
                <SavingProgressPopup {...savingProgressPopup}/>
            </form>
        )
    }
}

export default Newsletter