
import React from 'react';
import PropTypes from 'prop-types';
import SimpleTag from 'components/SimpleTag';
import cn from 'classnames';

import './SimpleTagList.scss'

const SimpleTagList = ({ items, className }) => {
    return items && items.length > 0 ? (
        <div className={cn('simple-tag-list', {[className]: className})}>
            <ul className="simple-tag-list__items">
                {items.map((item, index) => (
                    <li className="simple-tag-list__item" key={index}>
                        <SimpleTag text={item} />
                    </li>
                ))}
            </ul>
        </div>
    ) : null
}

SimpleTagList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string
};

SimpleTag.propTypesMeta = 'exclude';

export default SimpleTagList;