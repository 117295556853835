import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ArticleCard from 'components/ArticleCard'
import EmployeeCard from 'components/EmployeeCard'

import css from 'containers/SearchPage/SearchPage.scss'

const SearchResults = ({
    className,
    employees,
    pages,
    labels,
    loadMore,
    onClick,
    searching,
    submittedSearchQuery,
}) => {
    const pageResults = pages.results.length > 0 && (
        <section className={cn(css.results, 'list-items')}>
            <h2 className="h4">{labels.topResults}</h2>
            <div className="list-items-content three-grid">
                {pages.results.map(p => (
                    <a
                        className="list-item"
                        href={p.url}
                        key={p.id}
                    >
                        <ArticleCard
                            {...p}
                            className="no-large"
                            hasAccessLabel={labels.loginToSeeContent}
                        />
                    </a>
                ))}
            </div>
            {pages.moreLink && (
                <a
                    className={cn('button secondary small', css.seeMore)}
                    data-type="pages"
                    onClick={loadMore}
                >{labels.seeMoreResults}</a>
            )}
        </section>
    )

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            aria-label={labels.resultsTableAriaLabel}
            className={className}
            onClick={onClick}
        >
            {!employees.employeesShouldBeAtTheTop && pageResults}
            {employees.results.length > 0 && (
                <section className={cn(css.results, css.employeeList, 'list-items negative')}>
                    <h2 className="h4">{labels.employees}</h2>
                    <div className="list-items-content">
                        {employees.results.map(e => (
                            <div className="list-item employee" key={e.id}>
                                <EmployeeCard
                                    image={e.image}
                                    key={e.id}
                                    name={e.title}
                                    title={e.intro}
                                    url={e.url}
                                />
                            </div>
                        ))}
                    </div>
                    {employees.moreLink && (
                        <a
                            className={cn('button secondary small', css.seeMore)}
                            data-type="employees"
                            onClick={loadMore}
                        >{labels.seeMoreResults}</a>
                    )}
                </section>
            )}
            {employees.employeesShouldBeAtTheTop && pageResults}
            {submittedSearchQuery && !searching && employees.totalCount === 0 && pages.totalCount === 0 && (
                <div className={css.noResults}>
                    {labels && labels.noResultsFormat.format(submittedSearchQuery)}
                </div>
            )}
        </div>
    )
}

SearchResults.propTypes = {
    className: PropTypes.string,
    employees: PropTypes.any,
    pages: PropTypes.any,
    labels: PropTypes.shape({
        topResults: PropTypes.string.isRequired,
        loginToSeeContent: PropTypes.string.isRequired,
        employees: PropTypes.string.isRequired,
        seeMoreResults: PropTypes.string.isRequired,
        noResultsFormat: PropTypes.string.isRequired,
        resultsTableAriaLabel: PropTypes.string.isRequired
    }),
    loadMore: PropTypes.func,
    onClick: PropTypes.func,
    searching: PropTypes.bool.isRequired,
    submittedSearchQuery: PropTypes.string,
}

export default SearchResults
