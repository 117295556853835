import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types'
import Link from 'components/Link'
import './FilterShortcuts.scss'
import cn from 'classnames'
import Icon from 'components/Icon'
import idHelper from 'js/id-helper'

const FilterShortcuts = ({
    heading,
    dropdownOpenText,
    dropdownCloseText,
    links
}) => {
    const [isOpenedInMobile, setIsOpenedInMobile] = useState(false);
    const toggleOpenInMobile = () => {
        setIsOpenedInMobile(!isOpenedInMobile);
    };
    const dropdownText = isOpenedInMobile ? dropdownCloseText : dropdownOpenText;
    const uniqueFilterShortcutsId = useMemo(
        () => idHelper.generateUniqueId('filter-shortcuts-list'),
        []
    );

    return (
        <div className="filter-shortcuts">
            <h2 className="filter-shortcuts__heading h4">{heading}</h2>
            <button 
                className="button secondary toggle filter-shortcuts__mobile-toggle" 
                onClick={toggleOpenInMobile}
                aria-expanded={isOpenedInMobile}
                aria-controls={uniqueFilterShortcutsId}
            >
                {dropdownText}
                <Icon use={isOpenedInMobile ? 'caret-up' : 'caret-down'} />
            </button>
            <ul 
                id={uniqueFilterShortcutsId}
                className={cn('filter-shortcuts__list', {
                    'filter-shortcuts__list_opened-in-mobile': isOpenedInMobile
                })}
            >
                    {links &&
                        links.map((link, index) => (
                            <li key={index} className="filter-shortcuts__list-item">
                                <Link {...link} className="link_theme_shortcut" />
                            </li>
                        ))}
                </ul>
        </div>
    )
}

FilterShortcuts.propTypes = {
    heading: PropTypes.string.isRequired,
    dropdownOpenText: PropTypes.string.isRequired,
    dropdownCloseText: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
}

export default FilterShortcuts
