import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useState } from 'react'
import SasEventBus from '../../js/SasEventBus'
import css from './SasReportDispatcher.scss'

const ReportComponentsScriptUrl =
    '/Seafood/Static/build/sas/dist/umd/va-report-components.js'

const SasReportDispatcher = ({
    authenticationType,
    reportServerUrl,
    authFrameUrl,
    enableAuthFrame,
}) => {
    const [componentsLoaded, setComponentsLoaded] = useState(false)
    const [authFrameLoaded, setAuthFrameLoaded] = useState(false)
    const [someElementRegistered, setSomeElementRegistered] = useState(false)
    const [componentScriptsInjected, setComponentScriptsInjected] = useState(
        false
    )

    function checkAuthFrameSkipped() {
        return !(authFrameUrl && enableAuthFrame)
    }

    useEffect(() => {
        SasEventBus.onReportElementRegistration(() => {
            setSomeElementRegistered(true)
        })
    }, [])

    useEffect(() => {
        if (someElementRegistered && !componentScriptsInjected) {
            setComponentScriptsInjected(true)

            window.addEventListener('vaReportComponents.loaded', () =>
                setComponentsLoaded(true)
            )
            const script = document.createElement('script')
            script.src = ReportComponentsScriptUrl
            script.async = true
            document.body.appendChild(script)
        }
    }, [someElementRegistered])

    useEffect(() => {
        if (componentsLoaded && (authFrameLoaded || checkAuthFrameSkipped())) {
            const connectToServer = window.vaReportComponents.connectToServer
            connectToServer(reportServerUrl, {
                authenticationType: authenticationType.toLowerCase(),
            })

            SasEventBus.sendinitializationDone()
        }
    }, [componentsLoaded, authFrameLoaded])

    function handleAuthFrameLoaded() {
        if(!authFrameLoaded){
            setAuthFrameLoaded(true);
        }
    }

    return (
        <React.Fragment>
            {someElementRegistered && !checkAuthFrameSkipped() && (
                <iframe 
                    src={authFrameUrl}
                    height="0"
                    className={css['hidden-frame']}
                    onLoad={handleAuthFrameLoaded}
                ></iframe>
            )}
        </React.Fragment>
    )
}

SasReportDispatcher.propTypes = {
    authenticationType: PropTypes.string.isRequired,
    reportServerUrl: PropTypes.string.isRequired,
    authFrameUrl: PropTypes.string,
    enableAuthFrame: PropTypes.bool,
}

export default SasReportDispatcher
