import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import EmployeeCard from 'components/EmployeeCard'
import Anchor from 'components/Anchor'
import './LatestChroniclesBlock.scss'

const chronicleType = PropTypes.shape({
    url: PropTypes.string,
    heading: PropTypes.string,
    intro: PropTypes.string,
    author: PropTypes.exact(EmployeeCard)
})

import cn from 'classnames'

const themes = {
    shortView: 'short-view'
}

const LatestChroniclesBlock = ({ heading, chronicles, seeAllLink, theme, anchor }) => {
    return (
        <div
            className={cn('latest-chronicles-block', {
                [`latest-chronicles-block_theme_${theme}`]: theme
            })}
        >
            {heading && theme !== 'short-view' && (
                <h2 className="latest-chronicles-block__heading h4">
                    {anchor && <Anchor {...anchor} />}
                    {heading}
                </h2>
            )}
            {seeAllLink && (
                <Link
                    {...seeAllLink}
                    className="latest-chronicles-block__see-all-link see-all-link shortcut "
                />
            )}
            {chronicles && (
                <ul className="latest-chronicles-block__items">
                    {chronicles.map((chronicle, index) => (
                        <li
                            key={index}
                            className="latest-chronicles-block__item"
                        >
                            <div className="latest-chronicles-block__chronicle">
                                <a
                                    href={chronicle.url}
                                    className="latest-chronicles-block__chronicle-link"
                                >
                                    {chronicle.heading && (
                                        <h3 className="latest-chronicles-block__chronicle-heading">
                                            {chronicle.heading}
                                        </h3>
                                    )}
                                </a>

                                {chronicle.intro && (
                                    <div
                                        className="latest-chronicles-block__chronicle-intro typo-small wysiwyg"
                                        dangerouslySetInnerHTML={{
                                            __html: chronicle.intro.stripHtmlTags()
                                        }}
                                    />
                                )}
                                {chronicle.author && theme !== 'short-view' &&(
                                    <EmployeeCard
                                        {...chronicle.author}
                                        negative={false}
                                    />
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

LatestChroniclesBlock.propTypes = {
    anchor: PropTypes.exact(Anchor.propTypes),
    heading: PropTypes.string,
    seeAllLink: PropTypes.exact(Link.propTypes),
    chronicles: PropTypes.arrayOf(PropTypes.shape({ chronicleType })),
    theme: PropTypes.oneOf(Object.values(themes))
}

export default LatestChroniclesBlock
