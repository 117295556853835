import React from 'react'
import PropTypes from 'prop-types'
import CountryPageLinkButton from 'components/CountryPageLinkButton'
import './CountryPageSignUpForNewslettersBlock.scss'

const CountryPageSignUpForNewslettersBlock = ({ intro, buttonUrl, buttonText }) => {
    return (
        <div className='country-page-sign-up-for-newsletters-block'>
            <div className='country-page-sign-up-for-newsletters-block__container'>
                {intro && <div className='country-page-sign-up-for-newsletters-block__intro'>{intro}</div>}
                {buttonUrl && buttonText &&
                    <CountryPageLinkButton 
                        className='country-page-sign-up-for-newsletters-block__link'
                        url={buttonUrl}
                        text={buttonText}
                     />
                }
            </div>
        </div>
    ) 
}

CountryPageSignUpForNewslettersBlock.propTypes = {
    intro: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string
}

export default CountryPageSignUpForNewslettersBlock
