import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import jquery from 'jquery'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'

import FilterShortcuts from 'components/FilterShortcuts'
import Categories from 'components/Categories'
import Filters from 'components/Filters'
import FilterSearch from 'components/FilterSearch'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'

import css from './FilterList.scss'

import { filterSearch as trackSearch } from '../../tracking'
import FilterType from 'types/FilterType'

export default ComposedComponent => class extends React.Component {
    static displayName = 'FilterList'

    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        search: PropTypes.shape({
            labels: PropTypes.shape({
                title: PropTypes.string.isRequired,
                search: PropTypes.string.isRequired
            }).isRequired
        }),
        negative: PropTypes.bool.isRequired,
        labels: PropTypes.object.isRequired,
        filters: PropTypes.arrayOf(PropTypes.shape(FilterType)),
        rssLink: PropTypes.shape({
            link: PropTypes.string,
            text: PropTypes.string
        }),
        externalLink: PropTypes.shape({
            link: PropTypes.string,
            text: PropTypes.string
        }),
        internalLinks: PropTypes.arrayOf(PropTypes.shape({
            link: PropTypes.string,
            text: PropTypes.string
        })),
        initialItems: PropTypes.shape({
            items: PropTypes.array.isRequired,
            limit: PropTypes.number.isRequired,
            offset: PropTypes.number.isRequired,
            totalItems: PropTypes.number.isRequired
        }),
        tabs: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool.isRequired,
            link: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })),
        initialShown: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        url: PropTypes.string.isRequired,
        pagination: PropTypes.bool.isRequired,
        showItemCount: PropTypes.bool.isRequired,
        filtersOpen: PropTypes.bool.isRequired,
        searchTrackingEventName: PropTypes.string,
        query: PropTypes.string,
        helpText: PropTypes.string,
        shortcuts: PropTypes.exact(FilterShortcuts.propTypes)
    }

    static defaultProps = {
        negative: false,
        pagination: true,
        showItemCount: false,
        filtersOpen: false,
    }

    static childContextTypes = {
        labels: PropTypes.object
    }

    state = {
        query: null,
        searching: false,
        sortOptions: null,
        items: {
            items: [],
            limit: 0,
            offset: 0,
            totalItems: 0
        },
        shown: null,
        error: null
    }

    activeFilters = {}

    constructor(props) {
        super(props)

        if(props.query) {
            this.state.query = props.query // eslint-disable-line
        }

        if(props.initialItems) {
            this.state.items = props.initialItems // eslint-disable-line
        }

        if(props.initialShown) {
            this.state.shown = props.initialShown // eslint-disable-line
        }

        this._debouncedSearch = debounce(this._doSearch, 500)
        this._throttledHandleScroll = throttle(this._handleScroll, 100)
    }

    componentDidMount() {
        if(! this.props.initialItems) {
            this._getItems()
        }

        if(typeof window !== 'undefined') {
            if(window.location.hash && window.location.hash.length >= 2) {
                const id = window.location.hash.replace("#", "")
                const row = this.filterlist.querySelector(`tr[data-id="${id}"]`)

                if(! row) return

                this.setState({ shown: id }, () => {
                    window.setTimeout(() => {
                        const bodyRect = document.body.getBoundingClientRect(),
                            elemRect = row.getBoundingClientRect(),
                            offset   = elemRect.top - bodyRect.top - 100;

                        window.scrollTo(0, offset)
                    }, 250)
                })
            }

            window.addEventListener('scroll', this._throttledHandleScroll)
        }
    }

    getChildContext() {
        return { labels: this.props.labels }
    }

    _handleScroll = () => {
        const loader = document.getElementById('filterlist-loader')
        if(! loader || !this.component) return

        let { top, bottom } = this.component.getBoundingClientRect()
        const center = window.innerHeight / 2
        const margin = 100

        top += margin
        bottom = bottom-margin
        bottom = bottom-64

        if(top < center && bottom > center) {
            loader.setAttribute('style', `transform: translateY(${Math.floor(center-top)}px)`)
        } else if (top > center ) {
            loader.removeAttribute('style')
        }
    }

    _getUrlParameter(name) {
        var url = window.location.search.substring(1);
        var parameters = url.split('&');
    
        for (var i = 0; i < parameters.length; i++) {
            var parameterName = parameters[i].split('=');
    
            if (parameterName[0] === name) {
                return parameterName[1] === undefined ? null : decodeURIComponent(parameterName[1]);
            }
        }

        return null;
    }

    _getItems(filters, trackEvent = false) {
        let data = {}

        this.setState({ searching: true })

        if(typeof filters === 'object') {
            data = filters
        }

        var author = this._getUrlParameter('author');

        if (author)
        {
            data.author = author;
        }

        if(this.request && this.request.abort) this.request.abort()

        const start = Date.now()

        this.request = jquery.ajax({
            url: this.props.url,
            cache: false,
            contenType: 'application/json',
            dataType: 'json',
            data
        })
        .done(response => {
            // Only send a track event if we have a query and should be tracking
            if(trackEvent && data.q) {
                trackSearch(trackEvent, data.q, response.totalItems)
            }

            window.setTimeout(() => {
                this.setState({
                    items: {...response},
                    searching: false
                })
            }, 200 - (Date.now()-start)) // Make sure we always wait atleast 200ms for the opacity animation
        })
        .fail(error => this.setState({ error, searching: false}))

        return this.request
    }

    _onFilter = filters => {
        // Cache filter rules to be used in pagination
        this.activeFilters = filters ? filters : {}
        this._getItems(filters)
    }

    _onToggle = id => {
        if(this.state.shown === id) {
            this.setState({shown: null})
        } else {
            this.setState({shown: id})
        }
    }

    _onSorting = options => {
        this.setState({
            sortOptions: options
        })
    }

    _onChange = e => {
        this.q = e.target.value
        this._debouncedSearch(e.target.value)
    }

    _onClick = (e) => {
        e.preventDefault()

        if(this.q) {
            this._doSearch(this.q)
        }
    }

    _doSearch = query => {
        var queryObj = new Object;

        if(query){
            queryObj.q = query
        }
        if(this.state.sortOptions && this.state.sortOptions.key && this.state.sortOptions.direction){
            queryObj.sortKey = this.state.sortOptions.key;
            queryObj.sortDirection = this.state.sortOptions.direction;
        }
        
        this.setState({
            query
        } , () => {
            this._getItems(queryObj && queryObj, this.props.searchTrackingEventName)
        })
    }

    _getPage = page => {
        const {
            query,
            items: {
                limit
            }
        } = this.state

        let filter = {
            limit,
            offset: limit * (page-1),
            ...this.activeFilters
        }

        if(query) { filter.q = query }

        this._getItems(filter)

        if(this.filterlist && this.filterlist.scrollIntoView) {
            this.filterlist.scrollIntoView(true)
        }
    }

    _setCategory = e => {
        const { category } = e.currentTarget.dataset
        let filter

        if(category) {
            filter = { categories: category }
        }

        if(isEqual(this.activeFilters, filter)) return

        this.activeFilters = filter
        this._getItems(filter)
    }

    

    render() {
        const {
            categories,
            filters,
            filtersOpen,
            labels,
            search,
            negative,
            internalLinks,
            externalLink,
            rssLink,
            showItemCount,
            tabs,
            helpText,
            shortcuts
        } = this.props

        const {
            items: {
                items,
                totalItems,
                limit,
                offset,
                selectedCategory
            },
            shown,
            searching,
            query,
        } = this.state

        return (
            <div className={css.filterlist} ref={node => this.filterlist = node}>
                {shortcuts && <FilterShortcuts {...shortcuts} />}
                {helpText && <div className={css.helpText} dangerouslySetInnerHTML={{__html: helpText}} />}
                {search && (
                    <FilterSearch
                        {...search}
                        onChange={this._onChange}
                        onClick={this._onClick}
                        query={this.props.query}
                        searching={searching}
                    />
                )}
                {(filters || tabs) && (
                    <Filters
                        externalLink={externalLink}
                        filters={filters}
                        filtersOpen={filtersOpen}
                        internalLinks={internalLinks}
                        labels={labels}
                        negative={negative}
                        onFilter={this._onFilter}
                        rssLink={rssLink}
                        showItemCount={showItemCount}
                        tabs={tabs}
                        totalItems={totalItems}
                    />
                )}
                {categories && (
                    <Categories
                        categories={categories}
                        labels={labels}
                        onClick={this._setCategory}
                        selectedCategory={selectedCategory}
                    />
                )}
                {searching && <Loader className={css.loader} id="filterlist-loader" size="large" />}
                <div
                    className={cn(css.wrapper, {
                        [css.searching]: searching
                    })}
                    ref={node => this.component = node}
                >
                    <ComposedComponent
                        {...this.props}
                        items={items}
                        onToggle={this._onToggle}
                        onSorting={this._onSorting}
                        selectedCategory={selectedCategory}
                        shown={shown}
                        totalItems={totalItems}
                        searchValue={this.q}
                    />
                </div>
                {totalItems > limit && (
                    <Pagination
                        filters={this.activeFilters}
                        getPage={this._getPage}
                        limit={limit}
                        negative={negative}
                        nextLabel={labels.paginationNext}
                        offset={offset}
                        page={(offset / limit) + 1}
                        pageLabel={labels.paginationPage}
                        prevLabel={labels.paginationPrev}
                        query={query}
                        totalItems={totalItems}
                    />
                )}
            </div>
        )
    }
}
