import React from 'react'
import PropTypes from 'prop-types'
import PoorText from 'components/PoorText'
import './CountryPageActivitiesCampaignsBlock.scss'
import LinkWithIcon from 'components/LinkWithIcon'
import TypedLink from 'components/Models/TypedLink'

const CountryPageActivitiesCampaignsBlockItem = ({ 
    heading, 
    descriptionHtml,
    link
}) => {
    return (
        <div className='country-page-activities-campaigns-block__item'>
            {heading && <h2 className='country-page-activities-campaigns-block__item-heading'>{heading}</h2>}
            {descriptionHtml && <PoorText  className='country-page-activities-campaigns-block__item-description' text={descriptionHtml} />}
            {link && 
            <LinkWithIcon 
                {...link} 
                className='link-with-icon_in-country-page-activities-campaigns-block country-page-activities-campaigns-block__item-link' 
            />}
        </div>
    )
}

CountryPageActivitiesCampaignsBlockItem.propTypes = {
    heading: PropTypes.string,
    link: PropTypes.exact(TypedLink.propTypes),
    descriptionHtml: PropTypes.string
}

export default CountryPageActivitiesCampaignsBlockItem
