import PropTypes from 'prop-types'

const ExporterType = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    orgNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    streetAddresse: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    website: PropTypes.string,
    species: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })),
    countries: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }))
}

export default ExporterType
