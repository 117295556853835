import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './Link.scss'

const Link = ({ className, openInNewTab, url, text, ariaLabel, children }) => (
    <a
        href={url}
        aria-label={ariaLabel}
        className={cn('link', className)}
        target={openInNewTab ? '_blank' : null}
        rel={openInNewTab ? 'noopener noreferrer' : null} 
    >
        {children ? children : <span className="link__text">{text}</span>}
    </a>
)

Link.propTypes = {
    className: PropTypes.string,
    openInNewTab: PropTypes.bool,
    url: PropTypes.string,
    text: PropTypes.string,
    ariaLabel: PropTypes.string,
    children: PropTypes.node
}

Link.defaultProps = {
    openInNewTab: false
}

export default Link
