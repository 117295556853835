import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import Link from 'components/Link'
import PageSubscription from 'containers/PageSubscription'
import ResponsiveImage from 'components/ResponsiveImage'
import './NewsPageHeader.scss'
import cn from 'classnames'
import imageResizer from 'components/ResponsiveImage/ImageResizer.js';

const NewsPageHeader = ({
    heading,
    pageSubscription,
    externalLinks,
    image
}) => {
    const headerRef = useRef();
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        setImageWidth(imageResizer.calcImageWidth(window.screen.width, 100));
    });

    return (
        <header
            ref={headerRef}
            className={cn('news-page-header', {
                'news-page-header_has-background': image && image.src
            })}
        >
            {image && (
                <div className="news-page-header__image-container">
                    <ResponsiveImage 
                        {...image} 
                        fluid={true}
                        width={imageWidth}
                    />
                </div>
            )}
            <div className="news-page-header__text-container">
                {heading && <h1 className="news-page-header__heading">{heading}</h1>}
                <ul className="news-page-header__links-list">
                    {pageSubscription && (
                        <li className="news-page-header__links-list-item">
                            <PageSubscription 
                                {...pageSubscription} 
                                className="news-page-header__page-subscription page-subscription_style_reversed" 
                            />
                        </li>
                    )}
                    {externalLinks &&
                        externalLinks.map((link, index) => (
                            <li key={index}  className="news-page-header__links-list-item">
                                <Link {...link} className="link_theme_external" />
                            </li>
                        ))}
                </ul>
            </div>
        </header>
    )
}

NewsPageHeader.propTypes = {
    heading: PropTypes.string,
    pageSubscription: PropTypes.exact(PageSubscription.propTypes),
    externalLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
    image: PropTypes.exact(ResponsiveImage.propTypes)
}

export default NewsPageHeader
