import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import Link from 'components/Link'
import PoorText from 'components/PoorText'
import CountryPageLinkButton from 'components/CountryPageLinkButton'

import './CountryPageTextCtaImageBlock.scss'

const CountryPageTextCtaImageBlock = ({ heading, introHtml, image, ctaButton }) => {
    return (
        <div className="country-page-text-cta-image-block">
            <div className="country-page-text-cta-image-block__image-contaner">
                {image && <ResponsiveImage {...image}  className="responsive-image_size_country-page-text-cta-image-block" />}
            </div>
            <div  className="country-page-text-cta-image-block__text-contaner">
               {heading && <h2 className="country-page-text-cta-image-block__heading">{heading}</h2>}
                {introHtml && <PoorText className="country-page-text-cta-image-block__intro poor-text_in-country-page-text-cta-image-block" text={introHtml} />}
                {ctaButton && <CountryPageLinkButton 
                    {...ctaButton} 
                    iconName="arrow-right"  
                    className="country-page-text-cta-image-block__cta-button"
                />} 
            </div>
        </div>
    )
}

CountryPageTextCtaImageBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    image: PropTypes.exact(ResponsiveImage.propTypes),
    ctaButton: PropTypes.exact(Link.propTypes),
}

export default CountryPageTextCtaImageBlock
