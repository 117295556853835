import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import SasEventBus from '../../js/SasEventBus'
import { useEffect } from 'react'

const SasReportObject = ({
    reportUri,
    objectName,
    authenticationType,
    reportServerUrl,
}) => {
    var [initDone, setInitDone] = useState(false)

    useEffect(() => {
        SasEventBus.registerReportElement({elementType: "object", objectName: objectName})
    }, [])

    SasEventBus.onInitializationDone(() => setInitDone(true))

    return (
        <React.Fragment>
            {initDone && (
                <sas-report-object
                    objectName={objectName}
                    authenticationType={authenticationType.toLowerCase()}
                    url={reportServerUrl}
                    reportUri={reportUri}
                ></sas-report-object>
            )}
        </React.Fragment>
    )
}

SasReportObject.propTypes = {
    reportUri: PropTypes.string.isRequired,
    objectName: PropTypes.string.isRequired,
    authenticationType: PropTypes.string.isRequired,
    reportServerUrl: PropTypes.string.isRequired,
}

export default SasReportObject
