import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import cn from 'classnames'

import './ZoomButton.scss'

const ZoomButton = ({ isActive, className, onClick, ariaLabel }) => {
    return (
        <button className={cn("zoom-button", {[className]: className})} onClick={onClick} aria-label={ariaLabel}>
            <Icon className="zoom-button__icon" use={isActive ? "zoom-out" : "zoom-in"} />
        </button>
    )
}

ZoomButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    ariaLabel: PropTypes.string,
}

ZoomButton.propTypesMeta = 'exclude'

export default ZoomButton
