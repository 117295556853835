import React from 'react'
import PropTypes from 'prop-types';
import SelectFilterOption from './SelectFilterOption';
import FILTER_VARIANTS from './filter-variants';
import FILTER_SIZES from './filter-sizes';
import Icon from 'components/Icon';
import ReactSelectWrapper from 'components/ReactSelectWrapper';
import Select, { components } from 'react-select';
import cn from 'classnames';

import './SelectFilter.scss';

function formatValue(value) {   
    return {
		...value,
		value: value.value,
		label: value.text
	}
}

function getSelectedValues(items) {     
    return items && items.length > 0 ? items.filter(item => item.selected) : []
}

function getSingleSelectionValue(items) {   
	if (items && items.length > 0) {
		const selectedItems = getSelectedValues(items)
		if (selectedItems && selectedItems.length > 0) {
			return selectedItems[0].value
		}
		return null
	}
	return null
}

const SelectFilter = ({ 
	heading,
	placeholder,
	multiple = true, 
	disabled = false, 
	items, 
	name,
	dependantFilterNames,
	isLoading = false,
	noResultsText,
	variant = FILTER_VARIANTS.ROUNDED,
	size = FILTER_SIZES.MEDIUM,
	onChange = () => {} 
}) => {
	const onChangeHandler = (value) => {
		if (!multiple) {
			const currentValue = getSingleSelectionValue(items)
			if ((currentValue && currentValue === value.value) || (currentValue === null && value.value === null)) {
				return
			}
		}
		onChange(name, value, dependantFilterNames)
	}

	const DropdownIndicator = props => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<Icon use={props.selectProps.menuIsOpen ? 'caret-up-rounded' : 'caret-down-rounded'} className='select-filter__arrow' />
				</components.DropdownIndicator>
			)
		);
	};

	return (
		items && items.length > 0 ? 
			<div className={cn('select-filter', {
				[`select-filter_${variant}`]: variant,
				[`select-filter_size-${size}`]: size,
			})}>
				{heading && 
					<label 
						className={cn('select-filter__label', {
							'select-filter__label_small': variant === FILTER_VARIANTS.FLAT,
						})}
						htmlFor={name}
					>{heading}</label>
				}
				<ReactSelectWrapper>
					<Select
						backspaceToRemoveMessage=""
						components={{ DropdownIndicator }}
						closeOnSelect={!multiple}
						clearable={true}
						isDisabled={disabled}
						joinValues={true}
						isMulti={multiple}
						name={`filter_${name}`}
						onChange={onChangeHandler}
						options={items.map(formatValue)}
						placeholder={placeholder}
						simpleValue={true}
						isLoading={isLoading}
						labelKey="text"
						value={getSelectedValues(items.map(formatValue))}
						inputId={name}
						noOptionsMessage={() => noResultsText}
						classNamePrefix="Select"
					/>
				</ReactSelectWrapper>
			</div>
		: null
	)
};

SelectFilter.propTypes = {
	heading: PropTypes.string,
	placeholder: PropTypes.string,
	dependantFilterNames: PropTypes.arrayOf(PropTypes.string),
	items: PropTypes.arrayOf(PropTypes.exact(SelectFilterOption.propTypes)),
	name: PropTypes.string,
	noResultsText: PropTypes.string,
	multiple: PropTypes.bool,
	disabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	variant: PropTypes.oneOf(Object.values(FILTER_VARIANTS)),
	size: PropTypes.oneOf(Object.values(FILTER_SIZES)),
	onChange: PropTypes.func
};

SelectFilter.propTypesMeta = {
	noResultsText: 'exclude',
	multiple: 'exclude',
	disabled: 'exclude',
	onChange: 'exclude',
	isLoading: 'exclude',
	variant: 'exclude',
	size: 'exclude'
};

export default SelectFilter;
