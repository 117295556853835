import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import Link from 'components/Link'
import ResourceLinkType from 'types/ResourceLinkType'

const MarketInsightStatisticArchiveItem = ({
    heading,
    statisticsList,
    pdfLabel,
    excelLabel
}) => {
    return (
        statisticsList && statisticsList.length > 0 ? 
            <div className='market-insight-statistic-archive-item'>
                {heading && <span className='market-insight-statistic-archive-item__heading'>{heading}</span>}
                {statisticsList.map((row, rowIndex) => (
                    <div className='market-insight-statistic-archive-item__row' key={rowIndex}>
                        <div className='market-insight-statistic-archive-item__col market-insight-statistic-archive-item__col_info'>
                            <div className='market-insight-statistic-archive-item__cell market-insight-statistic-archive-item__cell-heading'>
                                {row.category}
                                {row.isRestricted && <Icon use="lock" className="market-insight-statistic-archive-item__icon" />}
                            </div>
                        </div>
                        <div className='market-insight-statistic-archive-item__col'>
                            <div className='market-insight-statistic-archive-item__cell'>
                                {row.pdfLinks && row.pdfLinks.length > 0 ? (
                                    row.pdfLinks.map((link, index) => (
                                        <div key={index} className='market-insight-statistic-archive-item__cell-item'>
                                            <Link ariaLabel={link.ariaLabel} text={pdfLabel} openInNewTab url={link.url} className='market-insight-statistic-archive-item__link' /> {link.size && <span>({link.size})</span>}
                                            <Icon use="pdf" className="market-insight-statistic-archive-item__icon hidden-desktop" />
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                        <div className='market-insight-statistic-archive-item__col'>
                            <div className='market-insight-statistic-archive-item__cell'>
                                {row.excelLinks && row.excelLinks.length > 0 ? (
                                    row.excelLinks.map((link, index) => (
                                        <div key={index} className='market-insight-statistic-archive-item__cell-item'>
                                            <Link ariaLabel={link.ariaLabel} text={excelLabel} openInNewTab url={link.url} className='market-insight-statistic-archive-item__link' /> {link.size && <span>({link.size})</span>}
                                            <Icon use="excel" className="market-insight-statistic-archive-item__icon hidden-desktop" />
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                    </div>
                ))}
            </div> 
        : null
    )
}

MarketInsightStatisticArchiveItem.propTypes = {
    heading: PropTypes.string,
    statisticsList: PropTypes.arrayOf(PropTypes.exact({
        category: PropTypes.string,
        isRestricted: PropTypes.bool.isRequired,
        pdfLinks: PropTypes.arrayOf(PropTypes.exact(ResourceLinkType)),
        excelLinks: PropTypes.arrayOf(PropTypes.exact(ResourceLinkType))
    })),
    pdfLabel: PropTypes.string,
    excelLabel: PropTypes.string,
}

MarketInsightStatisticArchiveItem.propTypesMeta = {
    pdfLabel: 'exclude',
    excelLabel: 'exclude'
}

export default MarketInsightStatisticArchiveItem
