import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const SelectOption = ({ value, label, isSelected, onChange }) => {
    return (
        <button className={cn('latest-articles-block__tag-filter-option', {
            'active': isSelected
        })}
        value={value}
        data-selected={isSelected}
        onClick={e => onChange(e)}
        disabled={isSelected}
        >
            <span className="latest-articles-block__tag-filter-option-text">{label}</span>
        </button>
    )
}

SelectOption.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    onChange: PropTypes.func
}

export default SelectOption
