import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import EmployeeCard from 'components/EmployeeCard'
import Link from 'components/Link'

import EmployeeType from 'types/EmployeeType'

import css from './EmployeeCardList.scss'

const EmployeeCardList = ({
    className,
    negative,
    title,
    employees,
    seeAllLink
}) => (
    <div className={cn(css.list, className)}>
        {title && <h2 className={cn('h4', css.title)}>{title}</h2>}
        {Array.isArray(employees) && employees.length > 0 && employees.map(e => (
            <EmployeeCard
                className={css.employee}
                key={e.id}
                {...e}
                negative={negative}
            />
        ))}
        {seeAllLink && (
            <div className={css.seeAllLinkHolder}>
                <Link 
                    {...seeAllLink} 
                    className={cn('shortcut typo-small', css.seeAllLink)}
                />
            </div>
        )}
    </div>
)

EmployeeCardList.propTypes = {
    className: PropTypes.string,
    negative: PropTypes.bool.isRequired,
    title: PropTypes.string,
    employees: PropTypes.arrayOf(PropTypes.shape(EmployeeType)).isRequired,
    seeAllLink: PropTypes.exact(Link.propTypes)
}

EmployeeCardList.defaultProps = {
    negative: false
}

export default EmployeeCardList
