import React from 'react'
import PropTypes from 'prop-types'
import './Photographer.scss'
import cn from 'classnames'

const Photographer = ({ lang, text, className }) => {
    return (
        <div className={cn('photographer', className)}>
            {lang && lang.photographerLabel && (
                <strong className="photographer__label">
                    {lang.photographerLabel}:{' '}
                </strong>
            )}
            {text}
        </div>
    )
}

Photographer.propTypes = {
    lang: PropTypes.shape({
        photographerLabel: PropTypes.string
    }),
    text: PropTypes.string,
    className: PropTypes.string
}

export default Photographer
