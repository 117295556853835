import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './ImageCarouselBlock.scss'
import ResponsiveImage from 'components/ResponsiveImage'
import MediaCaption from 'components/MediaCaption'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Thumbs } from 'swiper'

import ImageCarouselBlockSlide from './ImageCarouselBlockSlide'

const ImageCarouselBlock = ({ className, slides, inRichText = false }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)

    return slides && slides.length > 0 ? (
        <div
            className={cn('image-carousel-block', className, {
                'image-carousel-block_in-rich-text': inRichText
            })}
        >
            <div className="image-carousel-block__slides">
                <Swiper
                    modules={[Thumbs]}
                    thumbs={{ swiper: thumbsSwiper }}
                    slidesPerView={1}
                    onSlideChange={swiper =>
                        setActiveSlideIndex(swiper.activeIndex)
                    }
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={index} virtualIndex={index}>
                            <ImageCarouselBlockSlide {...slide} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {slides.length > 1 && (
                <div className="image-carousel-block__thumbnails">
                    <Swiper
                        modules={[Thumbs]}
                        watchSlidesProgress
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={5}
                    >
                        {slides &&
                            slides.map((image, i) => {
                                const isActive = i == activeSlideIndex
                                return (
                                    <SwiperSlide key={i} virtualIndex={i}>
                                        <div
                                            key={i}
                                            className={cn(
                                                'image-carousel-block__thumbnail',
                                                {
                                                    'image-carousel-block__thumbnail_active ': isActive
                                                }
                                            )}
                                        >
                                            <ResponsiveImage
                                                {...image.image}
                                                fluid
                                                className={cn(
                                                    'responsive-image_size_image-carousel-thumbnail image-carousel-block__thumbnail-image',
                                                    {
                                                        'responsive-image_size_image-carousel-thumbnail_active ': isActive
                                                    }
                                                )}
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                    </Swiper>
                </div>
            )}

            <MediaCaption
                textHtml={slides[activeSlideIndex].caption}
                className={cn('image-carousel-block__active-slide-caption', {
                    'image-carousel-block__active-slide-caption_single':
                        slides.length === 1
                })}
            />
        </div>
    ) : null
}

ImageCarouselBlock.propTypes = {
    inRichText: PropTypes.bool,
    className: PropTypes.string,
    slides: PropTypes.arrayOf(
        PropTypes.exact(ImageCarouselBlockSlide.propTypes)
    )
}

export default ImageCarouselBlock
