import React from 'react'
import PropTypes from 'prop-types'
import './CountryPageSectionHeadingBlock.scss'

const CountryPageSectionHeadingBlock = ({ heading, intro }) => {
    return (
        <div className="country-page-section-heading-block">
            {heading && <h2 className="country-page-section-heading-block__heading">{heading}</h2>}
            {intro &&<div className="country-page-section-heading-block__intro">{intro}</div>}
        </div>
    )
}

CountryPageSectionHeadingBlock.propTypes = {
    heading: PropTypes.string,
    intro: PropTypes.string
}

export default CountryPageSectionHeadingBlock
