import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './PoorText.scss'

const PoorText = ({ text, className }) => {
    return !text ? null : (
        <div
            className={cn('poor-text', className)}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    )
}

PoorText.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
}

PoorText.propTypesMeta = 'exclude'

export default PoorText
