import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import './SavingProgressPopup.scss'
import Modal from 'react-modal'
import Icon from 'components/Icon'
import cn from 'classnames'

const SavingProgressPopup = ({
    lang,
    loadingText,
    successText,
    failText,
    isSuccess = false,
    isFailed = false
}) => {
    const [isOpen, setIsOpen] = React.useState(isSuccess || isFailed)

    const $this = useRef()

    const text = isSuccess ? successText : isFailed ? failText : loadingText;
    const [isLoading, setIsLoading] = React.useState(false)
    const onPopupClose = () => {
        setIsOpen(false)
    }

    const onFormSubmit = () => {
        setIsLoading(true)
        setIsOpen(true)
    }

    useEffect(() => {
        const parentForm = $this.current.parentElement.closest('form')
        parentForm.addEventListener('submit', onFormSubmit)
    }, [])

    return (
        <div ref={$this}>
            <Modal
                isOpen={isOpen}
                closeTimeoutMS={500}
                ariaHideApp={false}
                className="saving-progress-popup__content"
                portalClassName="saving-progress-popup"
                onRequestClose={onPopupClose}
                shouldCloseOnEsc={!isLoading}
                shouldCloseOnOverlayClick={false}
                aria={{
                    label: lang.loadingPopupLabel
                }}
            >
                {!isLoading && (
                    <button
                        className="saving-progress-popup__close-btn"
                        onClick={onPopupClose}
                        aria-label={lang.closeLabel}
                    >
                        <Icon
                            use="close-small"
                            className="saving-progress-popup__close-btn-icon"
                        />
                    </button>
                )}

                {text && (
                    <div
                        className={cn('saving-progress-popup__text', {
                            'saving-progress-popup__text_failed':
                                isFailed && !isLoading
                        })}
                    >
                        {text}
                    </div>
                )}

                {isLoading ? (
                    <div className="saving-progress-popup__icon-container">
                        <div className="saving-progress-popup__loading-icon"></div>
                        <div className="saving-progress-popup__countdown"></div>
                    </div>
                ) : (
                    <React.Fragment>
                        {isSuccess && (
                            <div className="saving-progress-popup__icon-container">
                                <div className="saving-progress-popup__success-icon">
                                    <div className="saving-progress-popup__success-icon-part1"></div>
                                    <div className="saving-progress-popup__success-icon-part2"></div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </Modal>
        </div>
    )
}

SavingProgressPopup.propTypes = {
    lang: PropTypes.shape({
        closeLabel: PropTypes.string.isRequired,
        loadingPopupLabel: PropTypes.string.isRequired
    }).isRequired,
    loadingText: PropTypes.string,
    successText: PropTypes.string,
    failText: PropTypes.string,
    isSuccess: PropTypes.bool,
    isFailed: PropTypes.bool
}

export default SavingProgressPopup
