import { scrollToBlock } from '../utils';

document.addEventListener('DOMContentLoaded', () => {
    const hash = window.location.hash.slice(1);

    if (hash) {
        setTimeout(() => {
            const targetBlock = document.getElementById(hash);
    
            if (targetBlock) {
                scrollToBlock(hash);
            }
        }, 0)
    }
});
