import React from 'react'
import MarketInsightContactPersonsBlockContact from './MarketInsightContactPersonsBlockContact'
import Link from 'components/Link'
import LinkWithIcon from 'components/LinkWithIcon'
import PoorText from 'components/PoorText'
import PropTypes from 'prop-types'

import './MarketInsightContactPersonsBlock.scss'

const MarketInsightContactPersonsBlock = ({
    heading,
    introHtml,
    contacts,
    seeAllEmployeesLink
}) => {
    return (
        <div className='market-insight-contact-persons-block'>
            {heading && <h4 className='market-insight-contact-persons-block__heading'>{heading}</h4>}
            {introHtml && <PoorText text={introHtml} className='market-insight-contact-persons-block__intro poor-text_in-market-insight-contact-persons-block' />}
            {contacts && contacts.length > 0 ? 
                <div className='market-insight-contact-persons-block__contacts'>
                    {contacts.map((contact, index) => 
                        <MarketInsightContactPersonsBlockContact key={index} {...contact} />
                    )}
                </div>
            : null}
            {seeAllEmployeesLink && 
                <div className="market-insight-contact-persons-block__see-all-link-holder">
                    <LinkWithIcon 
                        {...seeAllEmployeesLink} 
                        className="market-insight-contact-persons-block__see-all-link"
                        size={LinkWithIcon.sizes.small}
                        darkMode={true}
                    />
                </div>
            }
        </div>
    )
}

MarketInsightContactPersonsBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.exact(MarketInsightContactPersonsBlockContact.propTypes)).isRequired,
    seeAllEmployeesLink: PropTypes.exact(Link.propTypes)
}

export default MarketInsightContactPersonsBlock