import React from 'react'
import PoorText from 'components/PoorText'
import LinkWithIcon from 'components/LinkWithIcon'
import MarketInsightLastPublications from 'components/MarketInsightLastPublications'
import Link from 'components/Link'
import MarketInsightCalendarDate from 'components/MarketInsightCalendarDate'
import PropTypes from 'prop-types'

import './MarketInsightCalendarBlock.scss'

const MarketInsightCalendarBlock = ({
    heading,
    introHtml,
    dates,
    allEventsLink,
    lastPublications,
    lang
}) => {
    return (
        <div className='market-insight-calendar-block'>
            <div className='market-insight-calendar-block__container'>
                {heading && <h2 className='market-insight-calendar-block__heading'>{heading}</h2>}
                {introHtml && <PoorText text={introHtml} className='poor-text_in-market-insight-calendar-block' />}
                <div className='market-insight-calendar-block__content'>
                    <div className='market-insight-calendar-block__list'>
                        {dates.map((date, index) => (
                            <div className='market-insight-calendar-block__item' key={index} >
                                <MarketInsightCalendarDate {...date}/>
                            </div>
                        ))}
                    </div>
                    {allEventsLink && 
                        <div className='market-insight-calendar-block__link-holder'>
                            <LinkWithIcon
                                {...allEventsLink}
                                className='market-insight-calendar-block__link'
                            />
                        </div>
                    }
                    {lastPublications && (
                        <MarketInsightLastPublications {...lastPublications} publishedLabel={lang.publishedLabel} />
                    )}
                </div>
            </div>
        </div>
    )
}

MarketInsightCalendarBlock.propTypes = {
    heading: PropTypes.string,
    introHtml: PropTypes.string,
    dates: PropTypes.arrayOf(PropTypes.exact(MarketInsightCalendarDate.propTypes)).isRequired,
    allEventsLink: PropTypes.exact(Link.propTypes),
    lastPublications: PropTypes.exact(MarketInsightLastPublications.propTypes),
    lang: PropTypes.shape({
        publishedLabel: PropTypes.string.isRequired,
    }).isRequired
}

export default MarketInsightCalendarBlock