import React from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import MediaCaption from 'components/MediaCaption'
import Photographer from 'components/Photographer'
import cn from 'classnames'

import './ImageTextBlock.scss'

export const imagePositions = {
    left: 'left',
    right: 'right'
}

const ImageTextBlock = ({ caption, image, photographer, imagePosition, inRichText = false }) => {
    return (
        <div className={cn("image-text-block", {
            'image-text-block_in-rich-text': inRichText
        })}>
            <div className={cn("image-text-block__container", {
                "image-text-block__container_reversed": imagePosition === imagePositions.right
            })}>
                <div className="image-text-block__image-col">
                    <ResponsiveImage 
                        className="responsive-image_size_image-text-block"
                        {...image} 
                        fluid={true}
                        transform="fill"
                    />
                </div>
                <div className="image-text-block__content-col">
                    {caption && (
                        <MediaCaption textHtml={caption} className="image-text-block__caption" />
                    )}
                    {photographer && 
                        <Photographer className="photographer_in-image-text-block" {...photographer} />
                    }
                </div>  
            </div>   
        </div>
    )
}

ImageTextBlock.defaultProps = {
    imagePosition: imagePositions.left
}

ImageTextBlock.propTypes = {
    image: PropTypes.exact(ResponsiveImage.propTypes),
    caption: PropTypes.string,
    photographer: PropTypes.exact(Photographer.propTypes),
    imagePosition: PropTypes.oneOf(Object.values(imagePositions)),
    inRichText: PropTypes.bool
}

export default ImageTextBlock
