import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import css from './FilterTableItem.scss'

const FilterTableRow = ({
    id,
    hide,
    className,
    children,
    colSpan,
    name,
    type,
    centered,
}) => (
    <tr
        className={cn('negative', css.item, className)}
        data-id={id}
        data-name={name}
        data-type={type}
        id={type + id}
        style={hide ? { display: 'none' } : {}}
    >
        <td colSpan={colSpan}>
            <div className={cn(css.wrapper, {
                [css.centered]: centered
            })}>
                {children}
            </div>
        </td>
    </tr>
)

FilterTableRow.defaultProps = {
    centered: true,
}

FilterTableRow.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    className: PropTypes.string,
    hide: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    colSpan: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    centered: PropTypes.bool.isRequired,
}

export default FilterTableRow
