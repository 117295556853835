import React from 'react'
import PropTypes from 'prop-types'
import ArticleCard from 'components/ArticleCard'
import Link from 'components/Link'
import TagFilter from './TagFilter'
import api from '../../js/api-helper'
import './LatestArticlesBlock.scss'
import cn from 'classnames'
import Anchor from 'components/Anchor'

const LatestArticlesBlock = ({
    anchor,
    heading,
    tagFilter,
    useTagFilters,
    seeAllLink,
    endpoint,
    errorText
}) => {
    const [isLoading, setIsloading] = React.useState(false)
    const [optionsList, setOptionsList] = React.useState(null)
    const [articlesList, setArticlesList] = React.useState(null)
    const [selectedTag, setSelectedTag] = React.useState(null)
    const [error, setError] = React.useState(null)

    const onChange = currentTag => {
        setSelectedTag(currentTag)
        updateList(currentTag)
    }

    const updateList = currentTag => {
        setIsloading(true);
        setError(null);
        api.execute(endpoint, { tag: currentTag || undefined })
            .then(response => {
                setIsloading(false)
                if (response.success) {
                    setArticlesList(response.data)
                } else {
                    setError(response.errorMessage || errorText)
                }
            })
            .catch(() => {
                setIsloading(false)
                setError(errorText)
            })
    }

    React.useEffect(() => {
        if (useTagFilters) {
            setOptionsList((tagFilter || {}).options)
        }
        updateList()
    }, [])

    return (
        <div className="latest-articles-block">
            {heading && (
                <h2 className="latest-articles-block__heading h4">
                    {anchor && <Anchor {...anchor} />}
                    {heading}
                </h2>
            )}
            {seeAllLink && (
                <Link
                    {...seeAllLink}
                    className="latest-articles-block__see-all-link see-all shortcut"
                />
            )}
            <div className="latest-articles-block__content">
                {optionsList && useTagFilters && (
                    <div
                        className={cn('latest-articles-block__filters', {
                            'latest-articles-block__filters_loading': isLoading
                        })}
                    >
                        <TagFilter
                            selectedAll={selectedTag ? false : true}
                            selectAllText={tagFilter.selectAllText}
                            options={optionsList}
                            onFilterChange={onChange}
                        />
                    </div>
                )}
                {error && <div className="latest-articles-block__error">{error}</div>}
                {articlesList && (
                    <div className="latest-articles-block__articles">
                        {articlesList.map((article, index) => (
                            <a
                                className="latest-articles-block__article"
                                key={index}
                                href={article.url}
                            >
                                <ArticleCard {...article} />
                            </a>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

LatestArticlesBlock.defaultProps = {
    useTagFilters: false
}

LatestArticlesBlock.propTypes = {
    anchor: PropTypes.exact(Anchor.propTypes),
    tagFilter: PropTypes.exact(TagFilter.propTypes),
    useTagFilters: PropTypes.bool,
    heading: PropTypes.string,
    seeAllLink: PropTypes.exact(Link.propTypes),
    endpoint: PropTypes.string,
    errorText: PropTypes.string,
    noResultsText: PropTypes.string
}

export default LatestArticlesBlock
