import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FilterTableItem from 'components/FilterTableItem'

import ExporterType from 'types/ExporterType'

import css from './Exporter.scss'

const Exporter = ({
    hide,
    labels,
    name,
    description,
    species,
    email,
    phoneNumber,
    countries,
    id,
    website,
}) => (
    <FilterTableItem
        colSpan={3}
        hide={hide}
        id={id}
        name={name}
        type="exporter"
    >
        <h2 className={cn(css.name, 'h1')}>{name}</h2>
        <div className={css.info}>
            <div
                className={cn(css.description, 'wysiwyg negative')}
                dangerouslySetInnerHTML={{__html: description}}
            />
            <div className={css.details}>
                {species && (
                    <div>
                        <h3 className="h4">{labels.species}</h3>
                        {species.map(s => (
                            <span className={cn('tag-label')} key={s.id}>
                                {s.name}
                            </span>
                        ))}
                    </div>
                )}
                {website && (
                    <div>
                        <h3 className="h4">{labels.website}</h3>
                        {website}
                    </div>
                )}
                <div>
                    <h3 className="h4">{labels.emailAddress}</h3>
                    {email}
                </div>
                <div>
                    <h3 className="h4">{labels.phoneNumber}</h3>
                    {phoneNumber}
                </div>
                {countries.length >= 1 && (
                    <div>
                        <h3 className="h4">
                            {countries.length === 1 ? labels.country : labels.countries}
                        </h3>
                        <ul>
                            {countries.map(c => (
                                <li key={c.id}>{c.name}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {email && (
                    <a className="button small negative" href={`mailto:${email}`}>{labels.contact}</a>
                )}
            </div>

        </div>
    </FilterTableItem>
)

Exporter.propTypes = {
    ...ExporterType,
    hide: PropTypes.bool.isRequired
}

export default Exporter
