import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import FluidImage from 'components/FluidImage'
import Link from 'components/Link'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import css from './NavigationArea.scss'

const renderNavigationItem = (item, index, nestingLevel = 0) => {
    let menuItem;
    const itemClassName = cn(css.navigationItem, {
        [css.navigationItemNested]: nestingLevel !== 0,
        [css.navigationItemDisabled]: item.isRestricted
    })
    const itemStyle = {
        marginLeft: nestingLevel * 17 + 'px',
        ...(nestingLevel >= 1 && { borderLeftColor: `rgba(64, 165, 216, ${(nestingLevel === 1) ? 1 : (1 / nestingLevel)})`}),
    }

    if (!item.hasNodes) {
        menuItem = <div key={index} className={itemClassName} style={itemStyle}>
            <a href={item.link.url} className={css.navigationLink}>{item.link.text}</a>
        </div>;
    }
    else {
        let menuItemChildren = item.nodes && item.nodes.length > 0 ? item.nodes.map((item, i) => {
            let menuItem = renderNavigationItem(item, i, nestingLevel + 1);
            return menuItem;
        }) : null;
        menuItem = 
        <React.Fragment>
            <div
                className={itemClassName} 
                style={itemStyle}
            >
                <a href={item.link.url} className={css.navigationLink}>{item.link.text}</a>
                <AccordionItemHeading>
                    <AccordionItemButton className={css.navigationArrowBtn}>
                        <i className={css.navigationArrowIcon}></i>       
                    </AccordionItemButton>
                </AccordionItemHeading>
            </div>
            <AccordionItemPanel>
                <Accordion allowZeroExpanded>
                    {menuItemChildren}
                </Accordion>
            </AccordionItemPanel>
        </React.Fragment>
    }

    return <React.Fragment key={index}><AccordionItem>{menuItem}</AccordionItem></React.Fragment>;
}

const NavigationArea = ({
    icon,
    heading,
    textHtml,
    nodes
}) => (
    <div className={css.navigationArea}>
        <div className={css.headingWrapper}>
            {icon && <img src={icon.url} alt={icon.alt} className={css.headingIcon} />}
            {heading && <h2 className={css.headingText}>{heading}</h2>}
        </div>
        {textHtml && <div dangerouslySetInnerHTML={{__html: textHtml}} className={css.description} />}
        {
            nodes.length > 0 ? nodes.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <Accordion allowZeroExpanded>
                            {
                                item && item.link ? (
                                    renderNavigationItem(item, index)
                                ) : null
                            }
                        </Accordion>
                    </React.Fragment>
                )
            }) 
            : null
        }
    </div>
)

const navigationNodeModel = {
    link: PropTypes.exact(Link.propTypes),
    hasNodes: PropTypes.bool,
    isRestricted: PropTypes.bool,
}

navigationNodeModel.nodes = PropTypes.arrayOf(PropTypes.shape(navigationNodeModel))

NavigationArea.propTypes = {
    heading: PropTypes.string,
    textHtml: PropTypes.string,
    icon: PropTypes.exact(FluidImage.propTypes),
    nodes: PropTypes.arrayOf(PropTypes.shape(navigationNodeModel)).isRequired
}

export default NavigationArea
