import jQuery from 'jquery'

window.addEventListener('DOMContentLoaded', () => {
    const myPage = document.querySelector('.my-page')

    if (!myPage) return

    const buttons = myPage.querySelectorAll('button[role="tab"]')

    function handleClick(e) {
        const button = e.currentTarget

        if (button.className.indexOf('active') === -1) {
            const activeButton = button.parentElement.querySelector('button.active[role="tab"]')

            activeButton.classList.remove('active')
            button.classList.add('active')

            const activeTab = myPage.querySelector(`[role="tabpanel"][data-tab="${activeButton.dataset.tab}"]`)
            jQuery(activeTab).animate({ opacity: .25 }, 200, () => {
                activeTab.removeAttribute('style')
                activeTab.classList.remove('active')

                const tab = myPage.querySelector(`[role="tabpanel"][data-tab="${button.dataset.tab}"]`)
                jQuery(tab).show().animate({ opacity: 1 }, 200, () => {
                    tab.removeAttribute('style')
                    tab.classList.add('active')
                })
            })
        }
    }

    for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i]
        button.addEventListener('click', handleClick)
    }
})
