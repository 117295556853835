import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import FilterTableItem from 'components/FilterTableItem'
import EmployeeCardList from 'components/EmployeeCardList'

import MaterialType from 'types/MaterialType'

import css from './Material.scss'

const Material = ({
    enriched,
    name,
    type,
    species,
    countries,
    languages,
    image,
    intro,
    attachment,
    employees,
    labels,
    hide,
    id,
    onOrderClick,
    onOrderListClick,
    coFinancing,
    hideOrderButton
}) => (
    <FilterTableItem
        colSpan={coFinancing ? 5 : 4}
        hide={hide}
        id={id}
        name={type}
        type="employee"
    >
        {image &&
            image.url && (
                <img className={css.image} src={image.url.downFit(360)} alt={image.alt ? image.alt : name ? name : ''} />
            )}
        {enriched ? (
            <h2 className={cn(css.title, 'h1')}>{name}</h2>
        ) : (
            <h2 className={cn(css.title, 'h1')}>
                {type}{' '}
                {species &&
                    species.length <= 2 &&
                    species.map(s => s.name).join(', ')}
                <br />
                {countries && countries.map(s => s.name).join(', ')}
            </h2>
        )}
        {enriched ? (
            <div className={css.tags}>
                <span className="tag-label">{type}</span>
                {languages &&
                    languages.map(l => (
                        <span className="tag-label" key={l.id}>
                            {l.name}
                        </span>
                    ))}
            </div>
        ) : (
            species && (
                <div className={css.tags}>
                    <h4>{labels.species}</h4>
                    {species.map(s => (
                        <span className="tag-label" key={s.id}>
                            {s.name}
                        </span>
                    ))}
                </div>
            )
        )}
        <div
            className={cn(css.intro, 'wysiwyg negative')}
            dangerouslySetInnerHTML={{ __html: intro }}
        />
        {!!employees.length && (
            <EmployeeCardList
                employees={employees}
                negative={true}
                title={labels.contacts}
            />
        )}
        {enriched &&
            (!attachment.name ? (
                <div className={css.attachment}>
                    <a
                        className="button negative"
                        href={attachment.url}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {labels.downloadMaterial}
                    </a>
                </div>
            ) : (
                <div className={css.attachment}>
                    <button
                        className="button negative"
                        onClick={() => onOrderClick(id)}
                    >
                        {labels.orderMaterial}
                    </button>
                    <button
                        className="button secondary negative"
                        onClick={onOrderListClick}
                    >
                        {labels.orderList}
                    </button>
                    <a
                        className={cn(
                            'external',
                            'external-negative',
                            css.preview
                        )}
                        href={attachment.url}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {labels.preview}
                    </a>
                </div>
            ))}
        {!enriched &&
            attachment &&
            attachment.url && (
                <div className={css.attachment}>
                    <a className="button negative" href={attachment.url}>
                        {labels.downloadMaterial}
                    </a>
                    {employees[0] &&
                        employees[0].email &&
                        !hideOrderButton && (
                            <a
                                className="button secondary negative"
                                href={`mailto:${employees[0].email}`}
                            >
                                {labels.orderMaterial}
                            </a>
                        )}
                </div>
            )}
    </FilterTableItem>
)

Material.propTypes = {
    ...MaterialType,
    hide: PropTypes.bool.isRequired,
    labels: PropTypes.shape({
        species: PropTypes.string.isRequired,
        contacts: PropTypes.string.isRequired,
        orderMaterial: PropTypes.string.isRequired,
        orderList: PropTypes.string.isRequired,
        preview: PropTypes.string.isRequired,
        downloadMaterial: PropTypes.string.isRequired
    }).isRequired,
    onOrderClick: PropTypes.func,
    onOrderListClick: PropTypes.func
}

Material.defaultProps = {
    enriched: false,
    coFinancing: false,
    hideOrderButton: false
}

export default Material
