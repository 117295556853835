import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Component to conditionally render its children only on the client-side.
// Useful for components or content that should not be executed or displayed during server-side rendering 
// to enhance performance and avoid potential issues with certain client-only functionality.

const ClientOnly = ({ children }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return isMounted ? children : null;
};

ClientOnly.propTypes = {
    children: PropTypes.node,
}

export default ClientOnly;