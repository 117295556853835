export function replaceStringPlaceholders(string, ...format) {
	let formatted = string;

	for (let index = 0; index < format.length; ++index) {
		const regExpString = `{${index}}`.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		const value = `${format[index]}`;

		formatted = formatted.replace(new RegExp(regExpString, 'g'), value);
	}

	return formatted;
}

export default {
	replaceStringPlaceholders
};